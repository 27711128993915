import React from "react";
import PropTypes from "prop-types";

const MDLayout = ({args}) => {
    return(
        <>
            <div className={args.counterSortRow}>
                <div className="col-lg-6">
                    {args.renderAllIncentiveCounter()}
                </div>
                <div className="col-lg-6">
                    {args.renderSortControl}
                </div>
            </div>
            <div className="row">
                <div 
                    className="col-lg-3"
                >
                    {args.renderAllIncentivesModal}
                    {args.renderOptions}
                </div>
                <div className="col-lg-9">
                    {args.renderChargerCatalog}
                </div>  
            </div>
        </>        
    )
}

export default MDLayout;

MDLayout.propTypes = {
    args: PropTypes.object
};