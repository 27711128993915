import React from "react";
import PropTypes from "prop-types";
import ToolTip from "../ToolTip/ToolTip";
import "./ButtonGroup.scss";

const ButtonGroup = props => {
  const { id, value, handler, optionNames, optionValues, label, tooltip, extraLabelClass = "" } = props;

  const labelClassName = `label-style modify-charger-incentives-label ${extraLabelClass} `;

  return (
    <div className="form-group ButtonGroup" id={id}>
      <span className={labelClassName}>{label} {tooltip && <ToolTip id={id + "_tooltip"} message={tooltip} />}</span>
      <div className="btn-grid-container">
        {optionValues.map((option, i) => {
          return (
            <button
              key={i}
              className={`col-md-5 btn text-center ${value === option ? "active" : ""}`}
              type="button"
              onClick={e => handler(e, option)}
            >
              {optionNames[i]}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default ButtonGroup;

ButtonGroup.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  handler: PropTypes.func,
  optionNames: PropTypes.array,
  optionValues: PropTypes.array,
  label: PropTypes.string,
  tooltip: PropTypes.string
};
