import React, { useState } from "react";
import PropTypes from "prop-types";
import IncentiveCard from "../../../components/IncentiveCard/IncentiveCard"
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner"
import orangeCircle from "../../assets/images/icons/orange-circle.svg"
import whiteCircle from "../../assets/images/icons/white-circle.svg"

const HomepageIncentiveCarousel = ({ incentives, titleText, excludeZeros, justify='justify-content-start', linkCards=true, buttonText=null}) => {
    const[selectedIncentive, setSelectedIncentive] = useState(0)
    if (!incentives) return <LoadingSpinner />;

  const renderCard = <IncentiveCard 
        incentive={incentives[selectedIncentive]} 
        key={1} 
        linkCards={linkCards}
        buttonText={buttonText}
    />; 

  const circleRow = incentives.map((incentive, index) => {
    if (index === selectedIncentive) {
        return (
            <img key={index} alt="orange circle" src={orangeCircle}/>
        )
    }
    return (
        <img key={index} alt="white circle" src={whiteCircle}/>
    )
  })

  return (
    <>
    <div className="col-xs-1 text-right p-0 m-0 d-flex justify-content-center align-items-center">
        <button
            className="btn btn-default carousel-button"
            style={{ height: "auto", fontSize: "40px" }}
            onClick={() =>
                selectedIncentive === 0 ? setSelectedIncentive(incentives.length - 1) : setSelectedIncentive(prev => prev - 1)
            }        >
            &lsaquo;
        </button>
    </div>
    <div className="col-xs-10 p-0 m-0">
        <div className="render-cards-container justify-content-center full-width">
            {renderCard}
        </div>
        <div className="row circle-container">
            {circleRow}
        </div>
    </div>
    <div className="col-xs-1 p-0 m-0 d-flex justify-content-center align-items-center">
        <button
            className="btn btn-default carousel-button"
            style={{ height: "auto", fontSize: "40px" }}
            onClick={() =>
                selectedIncentive === incentives.length - 1 ? setSelectedIncentive(0) : setSelectedIncentive(prev => prev + 1)
            }
        >
            &rsaquo;
        </button>
    </div>
    </>
  );
};

export default HomepageIncentiveCarousel;

HomepageIncentiveCarousel.propTypes = {
  incentives: PropTypes.array,
  titleText: PropTypes.element,
  excludeZeros: PropTypes.bool,
  justify: PropTypes.string,
  linkCards: PropTypes.bool,
  buttonText: PropTypes.string,

  // @TODO can we remove this?
  includeTotal: PropTypes.bool
};
