import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import Range from "../../shared/InputElements/Range";

function descriptionFn() {
  const intl = arguments[1]

  return (
    <div 
        className="install-cost-container row"
        style={{"marginTop": "0.5rem"}}
    >
        <div className="col-3 text-center">
            $0<br/>
            {intl.formatMessage({
              id: "install-cost-slider.none",
              defaultMessage: "None"
            })}
        </div>
        <div className="col-3 text-center">
            $500<br/>
            {intl.formatMessage({
              id: "install-cost-slider.low",
              defaultMessage: "Low"
            })}
        </div>
        <div className="col-3 text-center">
            $1000<br/>
            {intl.formatMessage({
              id: "install-cost-slider.average",
              defaultMessage: "Avg."
            })}
        </div>
        <div className="col-3 text-center">
            $2000<br/>
            {intl.formatMessage({
              id: "install-cost-slider.high",
              defaultMessage: "High"
            })}
        </div>
    </div>
  );
};

const SlideInstallationCost = ({
  id = "charger-installation-range",
  label = "Estimated Installation Cost",
  description = descriptionFn,
  handler,
  ...rest
}) => {
  const userPrefs = useContext(UserPrefsContext);
  const installCost = userPrefs.get("installCost");

  return (
    <Range
      id={id}
      value={installCost === "2000" 
        ? "1500"
        : installCost
      }
      label={label}
      labelClassName="modify-charger-incentives-label"
      rangeMin={0}
      rangeMax={1500}
      rangeStep={500}
      description={description}
      ariaControls="home-chargers-catalog"
      handler={handler}
      tooltipAutohide={false}
      {...rest}
    />
  );
};

export default SlideInstallationCost;

SlideInstallationCost.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.func
};
