// @TODO tooltips

import PropTypes from "prop-types";
import React, { useContext, useState, useEffect } from "react";
import ChargerWifiFilter from "../InputComponents/ChargerWifiFilter/ChargerWifiFilter";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import ControlledInputZipcode from "../InputComponents/InputZipcode/ControlledInputZipcode";
import ButtonGroup from "../shared/InputElements/ButtonGroup";
import SlideInstallationCost from "../InputComponents/SlideInstallationCost/SlideInstallationCost";
import {ValidateFiveDigitCode} from "../../utils/Helpers/USPostalCodeValidator";
import {FormattedMessage, useIntl } from 'react-intl';

const HomeChargersIncentiveModifierControls = ({zipcode}) => {
    const intl = useIntl();
    const userPrefs = useContext(UserPrefsContext);
    const [workingZipcode, setWorkingZipcode] = useState(zipcode);

    useEffect(() => {
        setWorkingZipcode(zipcode);
      }, [zipcode]);
    const inLIHEAP = userPrefs.get('enrolledLIHEAP');

    return(
        <div className="row input-well incentive-modifier">
            <div className="col-sm-12 col-md-6 col-lg-3 zip-input-group">
                <ControlledInputZipcode
                    zipcode={userPrefs.get("zipcode")}
                    workingZipcode={workingZipcode}
                    setZipcode={e => {
                        setWorkingZipcode(e.target.value);
                        if(ValidateFiveDigitCode(e.target.value)){
                                userPrefs.set({ zipcode: e.target.value });
                            }
                        }
                    }
                    id="input-zipcode-for-available-incentives"
                    isUpdating={userPrefs.zipcodeIsUpdating}
                    isNotFound={userPrefs.zipcodeIsNotFound}
                    hideValidateRequest
                />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3 install-cost-group">
                <SlideInstallationCost 
                    handler={(e) => {
                        const processedInstallCost = e.target.value === "1500"
                            ? "2000"
                            : e.target.value; 
                        
                        userPrefs.set({installCost: processedInstallCost});
                    }} 
                    tooltip={<FormattedMessage
                    id="home-charger-incentive-catalog.modifiers.installation-cost-tooltip"
                    defaultMessage="{blurb} {website}."
                    values={
                        {
                            blurb: <span>{intl.formatMessage({ id: "home-charger-incentive-catalog.modifiers.installation-cost", defaultMessage: "The cost to install your home charger will vary depending on many factors. The average installation cost is about $1,000. To learn more about the different cost levels visit our "})}</span>,
                            website: <a 
                                href="https://www.electricforall.org/faqs/"
                                target="_blank"
                                rel="noopener noreferrer"
                                >
                                    {intl.formatMessage({ id: "chargingandiveor.catalog.expo.visitfaq", defaultMessage: "FAQs"})} 
                                </a>
                        }
                    }/>}            
                    label={intl.formatMessage ? 
                        intl.formatMessage({ 
                            id: "chargingadvisor.costBreakdown.est-install-costs", 
                            defaultMessage: "Installation Cost"
                    }) : "Installation Cost"}
                    tooltipAutohide={false}              
                />    
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3 chargerWifiFilter">
                <div className="form-group">
                    <div className="btn-grid-container">
                        <ChargerWifiFilter
                            label={intl.formatMessage({ 
                                id: "homeChargers.wifi", 
                                defaultMessage: "Wifi Connectivity"
                            })}    
                            id="charger-wifi-incentives"
                            tooltip={intl.formatMessage ? 
                                intl.formatMessage({ 
                                    id: "home-charger-incentive-catalog.wifi-connex", 
                                    defaultMessage: "Some chargers have the ability to connect to your home’s WiFi network to allow for additional features.  WiFi connectivity may also allow you to qualify for additional incentives only available to these types of chargers."
                                }) : "Some chargers have the ability to connect to your home’s WIFI network to allow for additional features and it may allow you to qualify for receive additional incentives only available to these types of chargers."}         
                            handler={() => {
                            }}
                        />                            
                    </div>
                </div>
            </div>                        
            <div className="col-sm-12 col-md-6 col-lg-3 liheap-control-component">
                <ButtonGroup
                    id="liheap-indicator-buttons"
                    value={
                        inLIHEAP === 'tbd' ?
                        null :
                        inLIHEAP.toString()
                    }
                    label={intl.formatMessage ? 
                        intl.formatMessage({ 
                            id: "chargingadvisor.catalog.enrolledInLowIncome", 
                            defaultMessage: "Enrolled in Low Income Energy Program?"
                        }) : "Enrolled in Low Income Energy Program?"}
                    extraLabelClass = "longLabel"
                    optionNames={[intl.formatMessage({ id: "yes", defaultMessage: "Yes"}), intl.formatMessage({ id: "no", defaultMessage: "No"})]}
                    optionValues={["true", "false"]}
                    tooltip={intl.formatMessage ? 
                        intl.formatMessage({ 
                            id: "home-charger-incentive-catalog.liheap", 
                            defaultMessage: "If you are currently enrolled in your utility’s Low Income Home Energy Assistance Program (LIHEAP) you may be eligible to a savings booster."
                        }) : "If you are currently enrolled in your utility’s Low Income Home Energy Assistance Program (LIHEAP) you may be eligible to get additional incentives."}       
                    handler={(e, selected) => {
                            userPrefs.set({ enrolledLIHEAP: selected === "true" });
                        }
                    }
                />
            </div>                        
        </div>
    );
}

export default HomeChargersIncentiveModifierControls;

HomeChargersIncentiveModifierControls.propTypes = {
    zipcode: PropTypes.string,
  };