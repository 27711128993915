import PropTypes from "prop-types";
import React from "react";
import {FormattedMessage, useIntl} from 'react-intl';
import ModalComponent from "../ModalComponent/ModalComponent";
import IncentiveCatalog from "../IncentiveCatalog/IncentiveCatalog";

import discoverIncentivesRed from "../../assets/images/icons/discover-incentives-red.svg";
import arrowRight from "../../assets/images/icons/arrow-right.svg";
import icnIncentives from "../../assets/images/icons/icn_incentives.svg"
import "./AllIncentivesModal.scss";
import PriceDisclaimer from "../../../pages/HomeChargers/PriceDisclaimer";


const closeButton = (
  <div
    className="close-button"
  >
    ×
  </div>
);

const AllIncentivesModal = ({incentives}) => {
  const intl = useIntl();
    const buttonHTML = (<div 
                            className="show-all-incentives-button"
                        >
                            <div 
                                className="row"
                            >
                                <div 
                                    className="col-sm-2 col-xs-1"
                                    style={{paddingLeft: "0.5rem"}}>
                                    <img
                                        src={discoverIncentivesRed} 
                                        alt="discover incentives"
                                    />    
                                </div>
                                <div 
                                    className="col-sm-8 col-xs-9 content">
                                    <FormattedMessage 
                                        id="chargingadvisor.catalog.modal.allIncentivesLauncher"
                                        defaultMessage="View Your Available Incentives"
                                        description="Modal Button Text"
                                    />
                                </div>     
                                <div 
                                    className="col-sm-2 col-xs-1"
                                    style={{paddingTop: "0.5rem", paddingLeft: 0}}>
                                    <img
                                        src={arrowRight} 
                                        alt="right arrow"
                                    />    
                                </div>  
                            </div>
                        </div>);

    return (
        <ModalComponent
            buttonHTML={buttonHTML}
            className="all-incentives-modal"
            includeModalHeader={false}
            closeButton = {closeButton}
        >
          <div className="row text-center modal-html-title">
            <div className="col-md-10 col-md-offset-1">
              <div>
                <img 
                  src={icnIncentives}
                  alt="Incentive Icon"
                />
              </div>
              <div className="modal-title-h1 IntroBold">
                <FormattedMessage 
                  id="chargingadvisor.catalog.modal.allIncentivesHeading"
                  defaultMessage="All Charger Incentives"
                  description="Modal header"
                />
              </div>
              <div className="modal-title-h2">
                <FormattedMessage
                  id="chargingadvisor.catalog.modal.all-incentives-message"
                  defaultMessage="Charger Incentives For Your Zip Code"
                  description="Modal message"
                />
              </div>
            </div>
          </div>
        <IncentiveCatalog
          incentives={incentives}
          buttonText={intl.formatMessage({
            id: "chargingandiveor.catalog.expo.learnmore", 
            defaultMessage: "Learn More"        
          })} 
          justify="justify-content-center"
        />
      <PriceDisclaimer />  
      </ModalComponent>
    )
}

export default AllIncentivesModal;

AllIncentivesModal.propTypes = {
    incentives: PropTypes.array
}