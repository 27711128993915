import React from "react";
import PropTypes from "prop-types";

const MDLayout = ({args}) => {
    return(
        <>
            {args.renderFilterModal('xs')}
            <div className="row">
                <div className="col-xs-4">
                    {args.renderAllIncentiveCounter(true)}
                </div>
                <div className="col-xs-8">
                    {args.renderSortControl}
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 small-to-mid-modal-button">
                    {args.renderAllIncentivesModal}
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 charger-catalog-xs">
                    {args.renderChargerCatalog}
                </div>
            </div>
        </>        
    )
}

export default MDLayout;

MDLayout.propTypes = {
    args: PropTypes.object
};