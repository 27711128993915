import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";

import ChargerCatalog from "../../../client_customizations/components/ChargerCatalog/ChargerCatalog"
import ChargerSortControls from "../../../components/ChargerSortControls/ChargerSortControls";
import HomeChargersFilterControls from "../../../components/HomeChargersFilterControls/HomeChargersFilterControls";
import ModalComponent from "../../../components/ModalUIComponent/ModalUIComponent";
import ProductIncentiveCounter from "../../../components/shared/Counters/ProductIncentiveCounter";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import AllIncentivesModal from "../../../client_customizations/components/AllIncentivesModal/AllIncentivesModal"
import PriceDisclaimer from "../PriceDisclaimer";
import { useIntl } from 'react-intl';

import filterThenSortChargers from "../../../functions/chargers/filterThenSortChargers";
//import {checkIsBetween, getViewportWidth} from "../../../functions/misc";
//import {bootStrapSizes} from "../../../constants/constants";

import XSLayout from "./sizeLayouts/XSLayout";
import SMLayout from "./sizeLayouts/SMLayout";
import MDLayout from "./sizeLayouts/MDLayout";
import LGLayout from "./sizeLayouts/LGLayout";

import FiltersLauncher from "../../../client_customizations/assets/images/icons/filters_launcher.svg";

import "./HomeChargerIncentivesCatalog.scss";

const HomeChargerIncentives = ({
  homeChargers,
  incentives,
  setCostBreakdown,
  setSelectedCharger,
  ...props
}) => {
  const intl = useIntl();

  const params = new URLSearchParams(props.location.search);
  const fgs = params.get("fgs");

  useEffect(() => {
    document.title =
      process.env.REACT_APP_PAGES_HOME_CHARGERS_INCENTIVES_CATALOG_TITLE;
  });

  const userPrefs = useContext(UserPrefsContext);

  const filteredSortedHomeChargers = filterThenSortChargers(
    homeChargers,
    userPrefs
  );

  const renderOptions = (
    <>
      <HomeChargersFilterControls
        showWifiFilter={false}
        homeChargers={homeChargers}
      />
    </>
  );

  const renderAllIncentivesModal =
    homeChargers && homeChargers.length > 0 ? (
      <AllIncentivesModal incentives={incentives} />
    ) : null;

  const renderAllIncentiveCounter = (isMobile = false) => {
    return (
      <>
        <ProductIncentiveCounter
          products={filteredSortedHomeChargers}
          incentives={incentives}
          productName={intl.formatMessage({
            id: "homeChargers",
            defaultMessage: "home chargers",
          })}
          isMobile={isMobile}
        />
      </>
    );
  };

  const renderSortControl = (
    <div className="pull-right">
      <ChargerSortControls />
    </div>
  );

  const counterSortRow = "row counter-sort-chargers";

  const renderChargerCatalog = (
    <ChargerCatalog
      homeChargers={filteredSortedHomeChargers}
      incentivePricing={true}
      setCostBreakdown={setCostBreakdown}
      setSelectedCharger={setSelectedCharger}
    />
  );

  const renderFilterModal = (size) => {
    const FilterLauncherHTML = (
      <div className="filters-launcher-button">
        <img src={FiltersLauncher} alt="Filter Chargers" />
      </div>
    );

    const mdModal = (
      <ModalComponent buttonText="Filters" titleText="Refine Filters">
        {renderOptions}
      </ModalComponent>
    );
    const smModal = (
      <ModalComponent
        buttonHTML={FilterLauncherHTML}
        titleText="Refine Filters"
        buttonClassName="filter-sticky-launcher"
      >
        {renderOptions}
      </ModalComponent>
    );

    const sizes = {
      md: mdModal,
      sm: smModal,
      xs: smModal,
    };

    return sizes[size];
  };

  // @TODO put in functions folder once we can get this working - and if we need it
  /*
  const checkShouldRender = (bootStrapSize) => {
    const vpWidth = getViewportWidth();
    const sizeRange = bootStrapSizes[bootStrapSize];
    const shouldRender = checkIsBetween(vpWidth, sizeRange.min, sizeRange.max);

    return shouldRender;
  }
  */

  const args = {
    counterSortRow,
    renderAllIncentiveCounter,
    renderSortControl,
    renderAllIncentivesModal,
    renderOptions,
    renderChargerCatalog,
    renderFilterModal,
    // checkShouldRender
  };

  // @TODO would prefer not to have size-specific layout render at all if we're at the wrong size. Checks on this
  // are not working... better or worse to re-render each time the page resizes?
  /*
  const verifyLayout = (bootSize) => {
    return checkShouldRender(bootSize);
  };
  */

  // @TODO have section return null if its display property == none
  return (
    <section className="container">
      {fgs && (
        <>
          <img
            height="1"
            width="1"
            style={{ borderStyle: "none" }}
            alt=""
            src="https://insight.adsrvr.org/track/pxl/?adv=b494u39&ct=0:pr9idtz&fmt=3"
          />
          {/* Start of Floodlight Tag: Please do not remove
  Activity name of this tag: CM | Veloz | Get Started | Button
  URL of the webpage where the tag is expected to be placed: https://homecharging.electricforall.org/
  This tag must be placed between the <body> and </body> tags, as close as possible to the opening tag.
  Creation Date: 10/18/2022 */}
          <script type="text/javascript">
            var axel = Math.random() + ""; var a = axel * 10000000000000;
            document.write('
            <iframe
              // eslint-disable-next-line no-template-curly-in-string, jsx-a11y/aria-props
              src={"https://11521767.fls.doubleclick.net/activityi;src=11521767;type=mbc;cat=cmvel0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=' + a + '?1"}
              width="1"
              height="1"
              title="getStartedA"
              frameBorder="0"
              style={{ display: "none" }}
            ></iframe>
            ');
          </script>
          <noscript>
            <iframe
              // eslint-disable-next-line no-template-curly-in-string, jsx-a11y/aria-props
              src={"https://11521767.fls.doubleclick.net/activityi;src=11521767;type=mbc;cat=cmvel00;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=' + a + '?"}
              width="1"
              height="1"
              title="getStartedB"
              frameBorder="0"
              style={{ display: "none" }}
            ></iframe>
          </noscript>

          {/* End of Floodlight Tag: Please do not remove */}
        </>
      )}
      {/* Extra Small */}
      <section className="d-block d-sm-none">
        <XSLayout args={args} />
      </section>

      {/* Small */}
      <section className="d-none d-sm-block d-md-none">
        <SMLayout args={args} />
      </section>

      {/* Medium */}
      <section className="d-none d-md-block d-lg-none">
        <MDLayout args={args} />
      </section>

      {/* large and up */}
      <section className="d-none d-lg-block">
        <LGLayout args={args} />
      </section>

      <PriceDisclaimer />
    </section>
  );
};;

export default HomeChargerIncentives;

HomeChargerIncentives.propTypes = {
  homechargers: PropTypes.array,
  incentives: PropTypes.array,
  setCostBreakdown: PropTypes.func,
  setSelectedCharger: PropTypes.func
};