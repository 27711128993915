import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ModalComponent = (
  {
    children,
    submitText,
    buttonText,
    buttonGraphic,
    buttonHTML,
    titleText,
    className,
    includeModalHeader = true,
    closeButton = null,
    buttonClassName = ""
  }) => {

  const [showModal, setModal] = useState(false);
  const toggle = () => {
    setModal(!showModal);
  };

  closeButton = closeButton ?
    (<div className="close-button" onClick={toggle}>{closeButton}</div>
    ) :
    null;





  let renderFooter = submitText ? (
    <ModalFooter>
      <Button color="btn btn-ae" onClick={toggle}>
        {submitText}
      </Button>
    </ModalFooter>
  ) : null;

  const modalTextButton = (
    <button
      onClick={toggle}
      className={`btn btn-ae ${buttonClassName}`} type="button">
      {buttonText}
    </button>
  );

  const modalGraphicButton = (
    <img
      src={buttonGraphic}
      alt={buttonText}
      className={buttonClassName}
      onClick={toggle}
      style={{ cursor: "pointer" }}
    />
  )

  const modalHTMLButton = (
    <div
      onClick={toggle}
      style={{ cursor: 'pointer' }}
      className={buttonClassName}
    >
      {buttonHTML}
    </div>
  )

  const modalHeader = includeModalHeader ?
    (<ModalHeader toggle={toggle}>{titleText}</ModalHeader>) :
    null;

  // HTML supercedes buttonGraphic and buttonGraphic supercedes buttonText 
  const buttonType = () => {
    const returnType = buttonHTML ?
      modalHTMLButton :
      buttonGraphic ?
        modalGraphicButton :
        modalTextButton;

    return returnType;
  }

  return (
    <div>
      {buttonType()}
      <Modal isOpen={showModal} toggle={toggle} className={className}>
        {modalHeader}
        <ModalBody>    
        {closeButton}
          {children}
        </ModalBody>
      </Modal>
      {renderFooter}
    </div>
  );
};

export default ModalComponent;

ModalComponent.propTypes = {
  children: PropTypes.node.isRequired,
  buttonText: PropTypes.string,
  titleText: PropTypes.string,
  submitText: PropTypes.string,
  buttonGraphic: PropTypes.string,
  buttonHTML: PropTypes.element,
  className: PropTypes.string,
  includeModalHeader: PropTypes.bool,
  closeButton: PropTypes.element,
  buttomClassName: PropTypes.string
};
