// @TODO just a reminder to unify filters per https://zappyride.atlassian.net/browse/GENEVSHOP-77

const filterChargers = (chargers, filterParams) => {
    return chargers.filter(charger => {
      let keep = true;
      filterParams = filterParams || {};

      //  Filterting on SOCKET 
      if (filterParams.chargerFormFactorFilters) {
        let allowableFilterKeys = new Set()

        // @TODO if we somehow get a charger that does not have the home_outlet property, this will break the app.
        charger.home_outlet = charger.home_outlet || '';

        let socketTypes = charger.home_outlet.replace(/\s/g, '').split(",")
        Object.keys(filterParams.chargerFormFactorFilters).forEach(key => {
          if (filterParams.chargerFormFactorFilters[key])
            allowableFilterKeys.add(key);
        });
  
        if (allowableFilterKeys.size && keep) {
            let matchFound = false;
            for (const socket of socketTypes) {
                if (allowableFilterKeys.has(socket)) {
                    matchFound = true;
                }
            }

            keep = matchFound;
        }
      }
  
      if (filterParams.chargerTypeFilters) {
        let allowableFilterKeys = [];
        let apiChargerTypeHash = {"Wall mounted": "mounted", "Portable" : "portable"}
        Object.keys(filterParams.chargerTypeFilters).forEach(key => {
          if (filterParams.chargerTypeFilters[key])
            allowableFilterKeys.push(key);
        });
  
        if (
          allowableFilterKeys.length &&
          !allowableFilterKeys.includes(apiChargerTypeHash[charger.form_factor])
        )
          keep = false;
      }

      if (filterParams.chargerBudget && filterParams.chargerBudget < charger.price) {
          keep = false;
      }

      if (filterParams.chargerCordLength && filterParams.chargerCordLength < charger.cord_length) {
        keep = false;
      }

      if(filterParams.chargerBrandFilter && filterParams.chargerBrandFilter !== "All") {
        if(filterParams.chargerBrandFilter !== charger.make) {
          keep = false;
        }
      }

  
      return keep;
    });
  };
  
  export default filterChargers;
  