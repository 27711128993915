import React from "react";
import { useIntl } from 'react-intl';

import "./PriceDisclaimer.scss";

const PriceDisclaimer = () => {
    const intl = useIntl();

    return(
        <>
            {intl.formatMessage({ id: "price.disclaimer", defaultMessage: "Incentive, rebate and tax credit amounts may vary based on your individual circumstances. Please consult with your own tax or legal professional to determine eligibility, specific amount of incentives or rebates available."})}
        </>
    );
};

export default PriceDisclaimer;