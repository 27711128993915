import React, { Component} from "react";
import "url-search-params-polyfill";
import { Switch, Route, withRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./Root.scss";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import PageLayout from "../../components/PageLayout/PageLayout"

//import banner from "../../assets/images/banner.jpg";


import calculatorPreviewImage  from  "../../assets/images/calculator-preview.jpeg";
import experiencePreviewImage  from  "../../assets/images/experience-preview.png";



// Pages
//import Home from "../../components/HomePage/Homepage";
import EVs from "../../../pages/EVs/EVs";
import EVsGraph from "../../../pages/EVs/EVsGraph"
import EV from "../../../pages/EV/EV";
import UsedEV from "../../../pages/UsedEV/UsedEV";
import UsedEVs from "../../../pages/UsedEVs/UsedEVs";
import Incentives from "../../../pages/Incentives/Incentives";
import MapPage from "../../../pages/MapPage/MapPage";
import DealerPage from "../../../pages/DealerPage/DealerPage";
import FAQ from "../../../pages/FAQ/FAQ";
import FourOhFour from "../../../pages/FourOhFour/FourOhFour";
import CompareVehicles from "../../../components/CompareVehicles/CompareVehicles"
import HomeChargers from "../../../pages/HomeChargers/HomeChargers"
import HomeChargerAdvisorLanding from "../../../pages/HomeChargers/HomeChargerAdvisorLanding"
import HomeChargerIncentivesCatalog from "../../../pages/HomeChargers/HomeChargersIncentivesCatalog/HomeChargerIncentivesCatalog";
import ChargerIncentivesThreeSteps from "../../../pages/HomeChargers/ChargerIncentivesThreeSteps";
import TestNewComponent from "../../../pages/TestNewComponent/TestNewComponent";

// Services
import fetchElectricVehicles from "../../../services/fetchElectricVehicles";
import fetchGasolineVehicles from "../../../services/fetchGasolineVehicles"
import fetchIncentives from "../../../services/fetchIncentives";
import fetchHomeChargers from "../../../services/fetchHomeChargers"

// Utilities
import Uuid from "../../../utils/Uuid/Uuid";
import { loadState, persistState } from "../../../utils/LocalStorage/LocalStorage";
import loadUserPrefs from "../../../context/UserPrefs/loadUserPrefs";
import getUserPref from "../../../context/UserPrefs/getUserPref";
import USER_PREF_PRESETS from "../../../context/UserPrefs/USER_PREF_PRESETS";
import GaTracker from "../../../utils/GaTracker/GaTracker";
import isIE from "../../../utils/isIE";

class Root extends Component {
  constructor(props) {
    super(props);

    const existingState = loadState() || {};
    const savedPrefs =
      existingState && existingState.userPreferences
        ? existingState.userPreferences
        : {};

    if (!savedPrefs.vehicleFormFactorFilters)
      savedPrefs.vehicleFormFactorFilters = {};
    if (!savedPrefs.vehicleFuelTypeFilters)
      savedPrefs.vehicleFuelTypeFilters = {};
    if (!savedPrefs.chargerTypeFilters)
      savedPrefs.chargerTypeFilters = {};
    if (!savedPrefs.chargerFormFactorFilters)
    savedPrefs.chargerFormFactorFilters = {};
    if (!savedPrefs.chargerWifiFilter) {
      savedPrefs.chargerWifiFilter = USER_PREF_PRESETS.chargerWifiFilter;
    }
    if (!savedPrefs.chargerTypeFilters)
    savedPrefs.chargerTypeFilters = {}

    this.state = {
      uuid: existingState.uuid || Uuid(),
      ipData: existingState.ipData || null,
      chargingStations: null,
      electricVehicles: null,
      usedElectricVehicles: null,
      gasolineVehicles: null,
      incentives: null,
      incentivePrefsModalIsOpen: false,
      userLocation: null,
      userLocationNotFound: false,
      userLocationDealersNotFound: false,
      zipcodeUpdating: false,
      homeChargers: null,

      userPreferences: loadUserPrefs(savedPrefs)
    };
    // Last resort to ensure that the user has a UUID
    if (!this.state.uuid) this.state.uuid = Uuid();

    this.loadElectricVehicleData = this.loadElectricVehicleData.bind(this);
    this.loadGasolineVehicleData = this.loadGasolineVehicleData.bind(this);
    this.loadIncentivesData = this.loadIncentivesData.bind(this);
    this.updateUserPreferences = this.updateUserPreferences.bind(this);
    this.loadChargingStations = this.loadChargingStations.bind(this);
    this.geolocateUser = this.geolocateUser.bind(this);
    this.incentivePrefsModalToggle = this.incentivePrefsModalToggle.bind(this);
    this.loadDealers = this.loadDealers.bind(this);
  }

  componentDidMount() {
    this.geolocateUser();

    // this.loadElectricVehicleData();
    // this.loadGasolineVehicleData();
    this.loadZipcodeData();
    this.loadIncentivesData();
    // this.loadChargingStations();
    // this.loadDealers();
    this.loadHomeChargers({
      postcode: getUserPref("zipcode", this.state.userPreferences),
    });

    GaTracker.initialize();
    const page = this.props.location.pathname + this.props.location.search;
    GaTracker.trackPage(page, { userId: this.state.uuid });
  }

  // TODO: this should be cleaner
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.userPreferences.zipcode !==
      prevState.userPreferences.zipcode ||
      this.state.userPreferences.householdSize !==
      prevState.userPreferences.householdSize ||
      this.state.userPreferences.householdIncome !==
      prevState.userPreferences.householdIncome ||
      this.state.userPreferences.canTurnInClunker !==
      prevState.userPreferences.canTurnInClunker || 
      this.state.userPreferences.taxFilingStatus !== 
      prevState.userPreferences.taxFilingStatus 
    ) {
        this.loadElectricVehicleData();
        this.loadIncentivesData();
    } else if (
      this.state.userPreferences.vehicleIdForIncentives !==
      prevState.userPreferences.vehicleIdForIncentives ||
      this.state.userPreferences.vehicleHandleForIncentives !==
      prevState.userPreferences.vehicleHandleForIncentives
    ) {
      this.loadIncentivesData();
    }

    if (
      this.state.userPreferences.zipcode !== prevState.userPreferences.zipcode
    ) {
      this.loadZipcodeData();
      this.loadDealers();
    }

    let prevZip = prevState.userLocation ? prevState.userLocation.zip : null;
    if (this.state.userLocation && this.state.userLocation.zip !== prevZip) {
      this.loadChargingStations();
      this.loadDealers();
    }

    if (this.state.userLocation !==
      prevState.userLocation) {
        this.loadChargingStations();
      }

    if (!this.state.userLocationNotFound && !this.state.zipcodeUpdating) { 
      persistState(this.state); 
    }

    const currentPage = prevProps.location.pathname + prevProps.location.search;
    const nextPage = this.props.location.pathname + this.props.location.search;

    if (currentPage !== nextPage) {
      GaTracker.trackPage(nextPage, { userId: this.state.uuid });
    }

    if (this.props.language !== prevProps.language) {
      this.loadIncentivesData()
      this.loadElectricVehicleData()
      this.loadHomeChargers()
    }

    if (
      this.state.userPreferences.zipcode !== prevState.userPreferences.zipcode ||
      this.state.userPreferences.installCost !== prevState.userPreferences.installCost ||
      this.state.userPreferences.chargerWifiFilter !== prevState.userPreferences.chargerWifiFilter ||
      this.state.userPreferences.enrolledLIHEAP !== prevState.userPreferences.enrolledLIHEAP
      ) {
        this.loadHomeChargers()
      }
  }

  async loadElectricVehicleData() {
    let params = {
      postcode: getUserPref("zipcode", this.state.userPreferences),
      household_size: getUserPref("householdSize", this.state.userPreferences),
      household_income: getUserPref("householdIncome", this.state.userPreferences)
    };

    try {
      const electricVehicles = await fetchElectricVehicles(params);
      if(!electricVehicles) return; 
      this.setState({ electricVehicles: electricVehicles.newElectricVehicles, usedElectricVehicles: electricVehicles.usedElectricVehicles });
    } catch (e) {
      // TODO: handle errors here
    }
  }

  async loadGasolineVehicleData() {
    let params = {
      fuel_type:"gas",
      postcode: getUserPref("zipcode", this.state.userPreferences),
    }

    try {
      const gasolineVehicles = await fetchGasolineVehicles(params)
      if (!gasolineVehicles) return
      this.setState({gasolineVehicles})
    } catch (e) {

    }
  }

  async loadIncentivesData() {
    let params = {
      postcode: getUserPref("zipcode", this.state.userPreferences),
      lang: this.props.language === "EN" ? null : this.props.language
    };

    try {
      const incentives = await fetchIncentives(params);

      if(!incentives) return; 

      const chargerIncentives = incentives.filter((incentive) => {
        return incentive.support_for === 'Charging station';
      });

      this.setState({ incentives: chargerIncentives });
    } catch (e) {
      // TODO: handle error
    }
  }

  async loadHomeChargers() {
    let params = {
      postcode: getUserPref("zipcode", this.state.userPreferences),
      equipment_installation_cost: getUserPref("installCost", this.state.userPreferences),
      household_income: getUserPref("enrolledLIHEAP", this.state.userPreferences) ? 2000 : 150000,
      //wifi: getUserPref("chargerWifiFilter", this.state.userPreferences).yes ? true : false,
      lang: this.props.language === "EN" ? null : this.props.language
    }

    const wifiPrefs = getUserPref("chargerWifiFilter", this.state.userPreferences);
    if(wifiPrefs.yes) {
      params.wifi='true';
    } else if (wifiPrefs.no) {
      params.wifi = 'false';
    }


    try {
      const homeChargers = await fetchHomeChargers(params)
      if (!homeChargers) return
      this.setState({homeChargers})
    } catch (e) {

    }
  }

  loadChargingStations() {

    let zips =
      this.state.userLocation &&
        this.state.userLocation.nearby_postcodes &&
        this.state.userLocation.nearby_postcodes.length
        ? this.state.userLocation.nearby_postcodes.slice(1,200).join(",")
        : getUserPref("zipcode", this.state.userPreferences);
    let params = {
      fuel_type: "ELEC",
      format: "JSON",
      zip: zips,
      // NREL API doesn't handle preflight CORS requests properly, so we have to
      // include the API key as a GET query param instead of as a header
      api_key: process.env.REACT_APP_NREL_API_KEY
    };
    let url = new URL(
      "https://developer.nrel.gov/api/alt-fuel-stations/v1.json"
    );

    let searchParams = new URLSearchParams(params);

    url.search = searchParams;

    window.fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json"
      }
    });

    window
      .fetch(url)
      .then(response => response.json())
      .then(data => {
        this.setState({ chargingStations: data.fuel_stations });
      });
  }

  loadDealers() {
    if (!process.env.REACT_APP_PAGES_DEALERS_ENABLED) {
      return;
    }

    const params = {
      postcode: getUserPref("zipcode", this.state.userPreferences),
      nearby_distance: isIE() ? 25 : 100
    };
    let url = new URL(
      `${process.env.REACT_APP_EV_INFO_API_HOST}/dealers`
    );

    let searchParams = new URLSearchParams(params);

    url.search = searchParams;

    window
      .fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_EV_INFO_TKN}`
        }
      })
      .then(response => {
        if (response.status === 400) {
          this.setState({
            userLocationDealersNotFound: true,
          });
        }
        return response;
      })
      .then(response => response.json())
      .then(data => {
        if (data.dealers) {
          this.setState({
            dealerLocations: data.dealers,
            userLocationDealersNotFound: false
          });
        }
      });
  }


  loadZipcodeData() {
    const params = {
      postcode: getUserPref("zipcode", this.state.userPreferences),
      nearby_distance: isIE() ? 25 : 100
    };
    let url = new URL(
      `${process.env.REACT_APP_EV_INFO_API_HOST}/location`
    );

    let searchParams = new URLSearchParams(params);

    url.search = searchParams;

    window
      .fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_EV_INFO_TKN}`
        }
      })
      .then(response => {
        if (response.status === 400) {
          this.setState({
            userLocationNotFound: true,
            zipcodeUpdating: false
          });
        }
        return response;
      })
      .then(response => response.json())
      .then(data => {
        if (data.location) {
          this.setState({
            userLocation: data.location,
            userLocationNotFound: false,
            zipcodeUpdating: false
          });
          this.incentivePrefsModalToggle(false);
        }
      });
  }
  geolocateUser() {
    let url = new URL(
      `https://api.ipstack.com/check?format=json&access_key=${
      process.env.REACT_APP_IPSTACK_KEY
      }`
    );
    window
      .fetch(url)
      .then(response => response.json())
      .then(data => {
        if (this.state.ipData === null) {
          this.updateUserPreferences({zipcode: data.zip})
        } 
        this.setState({ ipData: data });
      });
  }

  updateUserPreferences(newPrefs) {
    let prefs = Object.assign({}, this.state.userPreferences, newPrefs);
    let newState = {
      userPreferences: prefs
    };
    if (
      newPrefs.zipcode &&
      this.state.userPreferences.zipcode !== newPrefs.zipcode
    )
      newState.zipcodeUpdating = true;
    this.setState(newState);
  }

  incentivePrefsModalToggle(override) {
    if (typeof override === "boolean") {
      this.setState({ incentivePrefsModalIsOpen: override });
    } else {
      this.setState({
        incentivePrefsModalIsOpen: !this.state.incentivePrefsModalIsOpen
      });
    }
  }

  render() {
    const ip = this.state.ipData ? this.state.ipData.ip : null;
    const uuid = this.state.uuid;
    const language = this.props.language
    const changeLanguage = this.props.changeLanguage

    const userPrefs = {
      get: key => getUserPref(key, this.state.userPreferences),
      getPreset: key => USER_PREF_PRESETS[key],
      set: this.updateUserPreferences,
      zipcodeIsNotFound: this.state.userLocationNotFound,
      zipcodeIsUpdating: this.state.zipcodeUpdating,
      showIncentivePrefsModal: this.state.incentivePrefsModalIsOpen,
      toggleIncentivePrefsModal: this.incentivePrefsModalToggle,
      syncWorkingZipcode: () =>
        this.updateUserPreferences({
          zipcode: getUserPref("workingZipcode", this.state.userPreferences)
        })
    };

    return (
      <UserPrefsContext.Provider value={userPrefs}>
        <Switch>
          <Route
            exact path="/"              
            render={props => {
              return (
                <PageLayout 
                  language={language}
                  changeLanguage={changeLanguage}
                  props={props}
                  ip={ip} 
                  uuid={uuid} 
                  page="Home Charging Advisor | Electric For All"
                  >
                    <HomeChargerAdvisorLanding 
                      zipcode={getUserPref("zipcode", this.state.userPreferences)}
                    />
                </PageLayout>
              );
            }}
          />
          {process.env.REACT_APP_PAGES_FAQ_ENABLED ? (
            <Route
              exact path="/faq"
              render={props => {
                return (
                  <PageLayout
                    props={props}
                    ip={ip} 
                    uuid={uuid}
                    description="Answers to Frequently Asked Questions about electric vehicles (EVs) and their incentives"
                    page ="FAQ | Generic EV Shopping Advisor" 
                    image={calculatorPreviewImage}             
                  >
                    <FAQ ip={ip} uuid={uuid} />;
                </PageLayout>
                )
              }}
            />
          ) : (null)}
          {process.env.REACT_APP_PAGES_VEHICLES_ENABLED ? (
            <Route
              exact path="/vehicles"
              render={props => {
                return (
                  <PageLayout 
                    language={language}
                    changeLanguage={changeLanguage}
                    props={props}
                    page="Vehicles | Generic EV Shopping Advisor" 
                    ip={ip} 
                    uuid={uuid}
                    description=""
                    >
                    <EVs
                      {...props}
                      electricVehicles={this.state.electricVehicles}
                      ip={ip}
                      uuid={uuid}
                    />
                  </PageLayout>
                );
              }}
            />
          ) : (null)}
        {process.env.REACT_APP_PAGES_USED_VEHICLES_ENABLED ? (
          <Route
            exact path="/used-vehicles"
            render={props => {
              return (
                <PageLayout 
                  language={language}
                  changeLanguage={changeLanguage}
                  props={props}
                  page="vehicles" 
                  ip={ip} e
                  uuid={uuid}>
                  <UsedEVs
                    {...props}
                    usedElectricVehicles={this.state.usedElectricVehicles}
                    ip={ip}
                    uuid={uuid}
                  />
                </PageLayout>
              );
            }}
          />
          ) : (null)}
          {process.env.REACT_APP_PAGES_USED_VEHICLES_ENABLED ? (
            <Route
            path="/used-vehicles/:evId"
            render={props => {
              return (
                <PageLayout 
                  language={language}
                  changeLanguage={changeLanguage}
                  props={props}
                  page="vehicles"
                  ip={ip} 
                  uuid={uuid}
                  disclaimers='NOTICE: The automotive images or video contained herein are owned by Fresh Interactive, LLC ("FUEL") and are protected under United States and international copyright law. FUEL reserves the right to pursue unauthorized users of its individual copyrighted images contained herein, each of which have independent economic value. These and other violations of FUEL intellectual property rights may result in your liability for actual damages and loss of income to FUEL and profits you derive from this misappropriation, or alternatively, for statutory damages per infringed work, plus all costs and attorneys fees. Access to and use of these images or video is restricted by the terms and conditions of a license agreement. Unauthorized use, reproduction, creation of derivative works, transmission, display or distribution of these images or video is strictly prohibited. FUEL reserves the right to pursue all legal and equitable remedies against unauthorized uses.'
                >

                  <UsedEV
                    {...props}
                    chargingStations={this.state.chargingStations}
                    electricVehicles={this.state.usedElectricVehicles}
                    userLocation={this.state.userLocation}
                    ip={ip}
                    uuid={uuid}
                  />
                </PageLayout>
              );
            }}
          />
        ) : (null)}
          {process.env.REACT_APP_PAGES_INCENTIVES_ENABLED ? (
            <Route
              exact path="/incentives"
              render={props => (
                <PageLayout
                  language={language}
                  changeLanguage={changeLanguage}
                  props={props}
                  ip={ip}
                  uuid={uuid}
                  page="Incentives | Generic EV Shopping Advisor"
                  description=""
                  disclaimers="Listed incentives may not be available at any given time. Listed incentives reflect an illustrative estimation of available incentives. {process.env.REACT_APP_FULL_COMPANY_NAME} does not recommend or endorse any particular automotive or insurance company."
                >
                  <Incentives
                    {...props}
                    electricVehicles={this.state.electricVehicles}
                    incentives={this.state.incentives}
                    image={experiencePreviewImage}
                    ip={ip}
                    uuid={uuid}
                  />
                </PageLayout>
              )}
            />
          ) : (null)}
          {process.env.REACT_APP_PAGES_CHARGING_ENABLED ? (
            <Route
              exact path="/charging-stations"
              render={props => {
                return (
                  <PageLayout 
                    language={language}
                    changeLanguage={changeLanguage}
                    props={props}
                    ip={ip} 
                    uuid={uuid} 
                    page="Charging Stations | Generic EV Shopping Advisor" 
                    description ="Find local public charging stations">
                  
                    <MapPage
                      {...props}
                      chargingStations={this.state.chargingStations}
                      userLocation={this.state.userLocation}
                      ip={ip}
                      uuid={uuid}
                    />
                  </PageLayout>
                );
              }}
            />
          ) : (null)}
          {process.env.REACT_APP_PAGES_DEALERS_ENABLED ? (
            <Route
              exact path="/dealers"
              render={props => {
                return (
                  <PageLayout 
                    language={language}
                    changeLanguage={changeLanguage}
                    props={props}
                    ip={ip} 
                    uuid={uuid} 
                    page="OEM Certrified Dealers | Generic EV Shopping Advisor"
                    description="Find OEM certified Electric Vehicle (EV) dearlers"
                    >
                    <DealerPage
                      {...props}
                      dealerLocations={this.state.dealerLocations}
                      userLocation={this.state.userLocation}
                      ip={ip}
                      uuid={uuid}
                    />
                  </PageLayout>
                );
              }}
            />
          ) : (null)}
          {process.env.REACT_APP_PAGES_VEHICLES_MODEL_ENABLED ? (
            <Route
              path="/vehicles/:evId"
              render={props => {
                return (
                  <PageLayout 
                    language={language}
                    changeLanguage={changeLanguage}
                    props={props}
                    page="Vehicles | Generic EV Shopping Advisor"
                    ip={ip} 
                    uuid={uuid}
                    description = "Compare Electric Vehicles (EVs) by price, range and features."
                    disclaimers='NOTICE: The automotive images or video contained herein are owned by Fresh Interactive, LLC ("FUEL") and are protected under United States and international copyright law. FUEL reserves the right to pursue unauthorized users of its individual copyrighted images contained herein, each of which have independent economic value. These and other violations of FUEL intellectual property rights may result in your liability for actual damages and loss of income to FUEL and profits you derive from this misappropriation, or alternatively, for statutory damages per infringed work, plus all costs and attorneys fees. Access to and use of these images or video is restricted by the terms and conditions of a license agreement. Unauthorized use, reproduction, creation of derivative works, transmission, display or distribution of these images or video is strictly prohibited. FUEL reserves the right to pursue all legal and equitable remedies against unauthorized uses.'
                  >
                    <EV
                      {...props}
                      chargingStations={this.state.chargingStations}
                      electricVehicles={this.state.electricVehicles}
                      userLocation={this.state.userLocation}
                      ip={ip}
                      uuid={uuid}
                    />
                  </PageLayout>
                );
              }}
            />
          ) : (null)}
        {process.env.REACT_APP_PAGES_COMPARE_ENABLED ? (
          <Route
            exact path="/compare-vehicles"
            render={props => {
              return (
                <PageLayout 
                  language={language}
                  changeLanguage={changeLanguage}
                  props={props}
                  page="vehicles"
                  ip={ip} 
                  uuid={uuid}>
                  <CompareVehicles
                    props={props}
                    ip={(this.state.ipData ? this.state.ipData.ip : null)}
                    uuid={this.state.uuid}
                    electricVehicles={this.state.electricVehicles}
                    gasolineVehicles={this.state.gasolineVehicles}
                  />
                </PageLayout>
              );
            }}
          />  
          ) : (null)}
          {process.env.REACT_APP_PAGES_COMPARISON_GRAPH_ENABLED ? (
            <Route
              exact path="/comparison-graph"
              render={props => {
                return (
                  <PageLayout 
                    props={props}
                    page="cost vs range" 
                    ip={ip} 
                    uuid={uuid}>
                    <EVsGraph
                      {...props}
                      electricVehicles={this.state.electricVehicles}
                      ip={ip}
                      uuid={uuid}
                      displayGraph={true}
                    />
                  </PageLayout>
                );
              }}
            />
          ) : (null)}
          {process.env.REACT_APP_PAGES_HOME_CHARGERS_ENABLED ? (
            <Route
              exact path="/home-chargers"
              render={props => {
                return (
                  <PageLayout 
                    language={language}
                    changeLanguage={changeLanguage}
                    props={props}
                    ip={ip} 
                    uuid={uuid} 
                    page="home chargers">
                    <HomeChargers
                      {...props}
                      homeChargers={this.state.homeChargers}
                      electricVehicles={this.state.electricVehicles}
                      ip={ip}
                      uuid={uuid}
                    />
                  </PageLayout>
                );
              }}
            />
          ) : (null)}
          {process.env.REACT_APP_PAGES_HOME_CHARGERS_INCENTIVES_ADVISOR_ENABLED ? (
            <Route
              exact path={process.env.REACT_APP_PAGES_HOME_CHARGERS_INCENTIVES_ADVISOR_NAVLINK}
              render={props => {
                return (
                  <PageLayout 
                    language={language}
                    changeLanguage={changeLanguage}
                    props={props}
                    ip={ip} 
                    uuid={uuid} 
                    page="Home Charging Advisor | Electric For All"
                  >
                      <HomeChargerAdvisorLanding />
                  </PageLayout>
                );
              }}
            />
          ) : (null)}
          {process.env.REACT_APP_PAGES_HOME_CHARGERS_INCENTIVES_CATALOG_ENABLED ? (
            <Route
              exact path={process.env.REACT_APP_PAGES_HOME_CHARGERS_INCENTIVES_CATALOG_NAVLINK}
              render={props => {
                return (
                  <PageLayout 
                    language={language}
                    changeLanguage={changeLanguage}
                    props={props}
                    ip={ip} 
                    uuid={uuid} 
                    page="home chargers advisor catalog"
                    zipcode={getUserPref("zipcode", this.state.userPreferences)}
                    >
                    <HomeChargerIncentivesCatalog
                      {...props}
                      homeChargers={this.state.homeChargers}
                      incentives={this.state.incentives}
                      setCostBreakdown={(chargerCostBreakdownComponent) => {
                        this.setState({'chargerCostBreakdownComponent': chargerCostBreakdownComponent});    
                      }}
                      setSelectedCharger={(selectedCharger) => {
                        this.setState({'selectedCharger': selectedCharger});
                      }}
                      ip={ip}
                      uuid={uuid}
                    />
                  </PageLayout>
                );
              }}
            />
          ) : (null)}
          {process.env.REACT_APP_PAGES_HOME_CHARGERS_THREE_STEPS_ENABLED ? (
            <Route
              exact path={process.env.REACT_APP_PAGES_HOME_CHARGERS_THREE_STEPS_NAVLINK}
              render={props => {
                return (
                  <PageLayout 
                    language={language}
                    changeLanguage={changeLanguage}
                    props={props}
                    ip={ip} 
                    uuid={uuid} 
                    page="home chargers advisor three steps">
                    <ChargerIncentivesThreeSteps 
                      {...props}
                      chargerCostBreakdownComponent={this.state.chargerCostBreakdownComponent}
                      charger={this.state.selectedCharger}
                    />
                  </PageLayout>
                );
              }}
            />
          ) : (null)}
          { process.env.REACT_APP_PAGES_CUSTOM_1_ENABLED  ? (
            <Route 
              exact path="/test-new-component"
              render={props => {
                return (
                  <PageLayout
                    language={language}
                    changeLanguage={changeLanguage}
                    props={props}
                    ip={ip}
                    uuid={uuid}
                    page="test new component"
                  >
                    <TestNewComponent />
                  </PageLayout>
                )
              }}
            />
          ) : (null)}
          <Route component={FourOhFour} />
                  </Switch>
      </UserPrefsContext.Provider >
        );
      }
    }
    
    export default withRouter(Root);
