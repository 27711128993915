import sortChargers from "./Sort/sortChargers"
import filterChargers from "./Filter/filterChargers"

/**
 * Filters and sorts chargers depending on userPrefs (themselves set by user changing filters).
 * 
 * @param {Array}       homeChargers    Array of chargers originally retrieved from API 
 * @param {*}           userPrefs       Used to make determinations of which chargers to exclude and how to sort. 
 */
const filterThenSortChargers = (homeChargers, userPrefs) => {

    // Only return null if homeChargers is null. We do not want to pass an empty array in this case.
    if(homeChargers === null) {
        return null;
    }

    // We need to preserve the original array of homeChargers! homeChargers should only change when there is an API call.
    let homeChargersClone = homeChargers.slice();

    homeChargersClone = filterChargers(homeChargersClone, {
        chargerFormFactorFilters: userPrefs.get("chargerFormFactorFilters"),
        chargerTypeFilters: userPrefs.get("chargerTypeFilters"),
        chargerWifi: userPrefs.get("chargerWifiFilter"),
        chargerBudget: userPrefs.get("chargerBudget"),
        chargerCordLength: userPrefs.get("chargerCordLength"),
        chargerBrandFilter: userPrefs.get("chargerBrandFilter")
    });

    homeChargersClone = sortChargers(
        homeChargersClone,
        userPrefs.get("chargerSortType"),
        userPrefs.get("chargerSortDirection"),
    );

    return homeChargersClone;

}

export default filterThenSortChargers;