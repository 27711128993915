/**
 * Builds table showing costs of charger and installation and savings from incentives.
 */

import PropTypes from "prop-types";
import React from "react";
import {FormattedMessage} from 'react-intl';

import ItemizedTotal from "../../shared/ProgrammaticTables/ItemizedTotal";
import {FormatAsDollars} from "../../../utils/Helpers/Format";
import {GetItemizedTotal} from "../../../functions/misc";
import distillToIncentiveValue from "../../../functions/incentives/distillToIncentiveValue";

//import ToolTip from "../../shared/ToolTip/ToolTip";

import "./ChargerCostBreakdown.scss";

/**
 * 
 * @param {*}   param0      props passed to component.
 *                          installation: installation cost supplied by user
 *                          charger: description of charger supplied by user
 *                          incentives: available incentives for user and specfic charger.
 *                          updateCostBreakdown: callback that enables other components to use calculated totals
 * 
 * @returns     DOMElement
 */
// @TODO may want to have costsArray and incentiveArray be arguments here instead of deriving them within the function.
const ChargerCostBreakdown = ({
    installation, 
    charger = {}, 
    incentives = [], 
    updateCostBreakdown = (prop, val) => {}
}) => {

    const costsArray = [
        {
            name: `${charger.make} ${charger.model}`,
            value: charger.price
        }, 
        {
            name: (
                <FormattedMessage 
                    id="chargingadvisor.costBreakdown.est-install-costs"
                    defaultMessage="Estimated Installation Costs"
                    description="Estimated Installation Costs"
                />
                ),
            value: installation,
            specialRowClass: 'is-approx'
        },
    ]

    // @TODO move out to functions modules.... pass in costArray and incentives
    const totalCost = GetItemizedTotal(costsArray);

    const incentiveValueArray = distillToIncentiveValue(incentives);

    const totalIncentiveValue = GetItemizedTotal(incentiveValueArray);

    updateCostBreakdown('costs', totalCost);
    updateCostBreakdown('incentives', totalIncentiveValue);
    updateCostBreakdown('finalPrice', totalCost - totalIncentiveValue);

    // @TODO tooltip if null
    /*
    const negativePriceToolTip = ((totalCost - totalIncentiveValue) < 0) ?
        null :
        null;
  */        

    return (
        <div className="charger-cost">

            <div className="breakdown-table-cat">
                <h3><FormattedMessage 
                        id="chargingadvisor.costBreakdown.costs"
                        defaultMessage="Costs"
                        description="Costs"
                    /></h3>
                <ItemizedTotal
                    moneyFormat={true}
                    itemList={costsArray}
                    totalName={(
                        <FormattedMessage 
                            id="chargingadvisor.costBreakdown.totalCost"
                            defaultMessage="Total Cost"
                            description="Total Cost"
                        />
                    )}
                />
                <h3><FormattedMessage 
                        id="chargingadvisor.costBreakdown.incentives"
                        defaultMessage="Incentives"
                        description="Incentives"
                    /></h3>
                <ItemizedTotal 
                    moneyFormat={true}
                    itemList={incentiveValueArray}
                    totalName={(
                        <FormattedMessage 
                            id="chargingadvisor.costBreakdown.totalIncentives"
                            defaultMessage="Total Incentives"
                            description="Total Incentives"
                        />
                    )}
                />
                <table 
                    className="itemized-cost-table table table-sm table-bordered"
                >
                    <tbody>
                        <tr className="final-charger-cost">
                            <th 
                                scope="row"
                                className="itemized-total-name align-middle"
                            >                            
                            <FormattedMessage 
                                id="chargingadvisor.costBreakdown.costAfterIncentives"
                                defaultMessage="Estimated project cost after incentives"
                                description="Cost after incentives"
                            />
                        </th>

                            <td className="itemized-total-value align-middle">{FormatAsDollars(totalCost - totalIncentiveValue)}</td>
                        </tr>
                    </tbody>
                </table>

            </div>
       </div>
    );
}

export default ChargerCostBreakdown;

ChargerCostBreakdown.propTypes = {
    installation: PropTypes.number,
    charger: PropTypes.object,
    incentives: PropTypes.array
};