import "react-app-polyfill/ie11";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import Root from "./client_customizations/containers/Root/Root";
import { Router } from "react-router-dom";
import {IntlProvider} from "react-intl";
import * as serviceWorker from "./serviceWorker";
import "./utils/polyfills";
import messages_en from "./client_customizations/translations/en.js";
import messages_esp from "./client_customizations/translations/esp.js";
import history from "./history";

const App = () => {
  const [language, setLanguage] = useState('EN');

  const changeLanguage = (language) => {
    setLanguage(language);
  }

  const messages = {
    'EN': messages_en,
    'ES' : messages_esp
  };

  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <Router history={history}>
          <Root language={language} changeLanguage={changeLanguage}/>
      </Router>
    </IntlProvider>
  )
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);

// ReactDOM.render(
//   <IntlProvider locale={language} messages={messages[language]}>
//     <Router>
//         <Root changeLanguage={changeLanguage}/>
//     </Router>
//   </IntlProvider>,
//   document.getElementById("root")
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
