import React, { useContext } from "react";
import PropTypes from "prop-types";
import unique from "lodash/uniq";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext"

import Select from "../../shared/InputElements/Select"
import { useIntl } from 'react-intl';

const SelectChargerBrandFilter = ({
  id = "select-charger-brand-filter",
  chargers,
  ...rest
}) => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);
  const chargerBrandOptions = unique((chargers || []).map((charger) => charger.make));
  const label = intl.formatMessage ? intl.formatMessage({ id: "brand", defaultMessage: "Brand"}) : "Brand"

  const sortedChargerBrandOptions = chargerBrandOptions.slice().sort();

  return (
    <Select
      id={id}
      value={userPrefs.get("chargerBrandFilter")}
      label={label}
      optionNames={[intl.formatMessage ? intl.formatMessage({ id: "all", defaultMessage: "All"}): "All", ...sortedChargerBrandOptions]}
      optionValues={["All", ...sortedChargerBrandOptions]}
      handler={(e) => userPrefs.set({ chargerBrandFilter: e.target.value })}
    />
  );
};

export default SelectChargerBrandFilter;

SelectChargerBrandFilter.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
};
