import React from "react";
import {FormattedMessage} from 'react-intl';
import PropTypes from "prop-types";

import { FormatAsDollars } from "./../../utils/Helpers/Format";

import "./IncentiveCard.scss";

const IncentiveCard = props => {
  let incentive = props && props.incentive ? props.incentive : {};

  let eligibility = incentive.evaluation && incentive.evaluation.eligibility;

  let linkCards = props && (props.linkCards !== undefined) ? props.linkCards : true;

  if(props.buttonText) {
    linkCards = false;
  }

  const renderButton = props.buttonText ?
    ( 
      <div className="buttonWrapper">
        <div className="buttonLink">
          <a
            className="evc-card IncentiveCard"
            href={incentive.details_url}
            target="_blank"
            rel="noopener noreferrer"
          >

            <button className="btn btn-ae incentive-card-button">
              <FormattedMessage 
                id="incentive-card.button-text"
                defaultMessage={props.buttonText}
              />
            </button> 
          </a>
        </div>
      </div>
    ): null;
  
  if (eligibility === "ineligible") {
    return null
  }

  if (props.usedEv === true && props.incentive.applicable_to_used_vehicles === false) {
    return null 
  }
    
  let name = incentive.grantor + " " + incentive.name || "";
  let description = incentive.description || "";
  let type = incentive.type;
  const type_for_color = incentive.type_for_color;
  const secondaryDescription = incentive.description2 || '';
  
  let amountDescription =
    incentive.typical_amount && incentive.typical_amount !== "N/A"
      ? incentive.typical_amount.replace("-$", "-")
      : "";

  let amount =
    incentive.evaluation &&
    incentive.evaluation.amount_in_purchase &&
    parseInt(incentive.evaluation.amount_in_purchase, 10) > 0
      ? parseInt(incentive.evaluation.amount_in_purchase, 10)
      : 0;

  let incentiveClassColor = type => {
    switch (type) {
      case 'Tax credit':
        return 'green'
      case 'HOV lane':
        return 'orange'
      case 'At Installation':
        return 'orange'  
      case 'Rebate':
        return 'aqua'
      case 'Discounted Electric Rate':
        return 'green'
      case 'At Purchase':
        return 'aqua'
      case 'Other Programs':
        return 'gray'
      default:
        return 'green';
    }
  }

  const renderCardImg = (
    <>
      <div className={`IncentiveCardTop ${incentiveClassColor(type_for_color || type)}`}>
        <span>{type}</span>
      </div>
      <div className="IncentiveCardBody">
        <p className="card-title">
          {amount ? FormatAsDollars(amount) : amountDescription}
        </p>
        <p className="IncentiveCardContent">{name ? name : ""}</p>
      </div>
      <div className="pl-5 pr-5">
        <hr />
      </div>
      <div className="IncentiveCardBottom">
        <>
          {description}
          <div className="card-secondary-description">
            {secondaryDescription}
          </div>
          {renderButton}
        </>
      </div>
    </>
  );

  let renderCard = incentive ? 
    linkCards ? (
      <a
        className="evc-card IncentiveCard"
        href={incentive.details_url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {renderCardImg}
      </a>
      ) : (
        <div 
          className="evc-card IncentiveCard"
          style={{
            cursor: "default"
          }}
        >
          {renderCardImg}
        </div>
      ) 
   : null;
  
  return renderCard;
};

export default IncentiveCard;

IncentiveCard.propTypes = {
  incentive: PropTypes.object,
  compact: PropTypes.bool,
  linkCard: PropTypes.bool
};