/**
 * 
 * @param {Array}   incentives    An array of full incentive objects
 * 
 * @returns {Array} Simplified array containing incentive name and financial value. Note that if the incentive 
 *                  is not financial, or if the evaluations property is missing, this incentive will not be added. 
 */
const distillToIncentiveValue = (incentives, leaseOrPurchase = 'purchase') => {
    const leaseOrPurchaseProps = {
        lease: 'amount_in_lease',
        purchase: 'amount_in_purchase'
    };
    const filterIncentives = ((incentive) => {
        return (incentive.evaluation && incentive.evaluation.eligibility === 'eligible'); 
    });
    const validIncentives = incentives.filter(filterIncentives);
    const distilledIncentives = [];

    // Note that a value of zero is OK. In such a case we still want to affirm that the incentive was considered, 
    // but has no impact in this case. Up to handlers whether to exclude 0's.
    validIncentives.forEach((incentive) => {
        distilledIncentives.push({
            name: incentive.name,
            value: incentive.evaluation[leaseOrPurchaseProps[leaseOrPurchase]]
        });
    })

    return distilledIncentives;
}

export default distillToIncentiveValue;