import React from "react";
import PropTypes from "prop-types";
import { UncontrolledTooltip } from "reactstrap";
import q from "../../../client_customizations/assets/images/icons/icon-i.svg";

const ToolTip = ({ 
  id, 
  message, 
  autohide = true, 
  icon = q
}) => {
  return (
    <>
      <span id={id} style={{ display: "inline-block" }}>
        <img
          src={icon}
          alt=""
          style={{ width: "16px", marginLeft: "5px", marginBottom: "3px" }}
        />
      </span>
      <UncontrolledTooltip 
        placement="right" 
        target={id}
        autohide={autohide}
      >
        <span>{message}</span>
      </UncontrolledTooltip>
    </>
  );
};

export default ToolTip;

ToolTip.propTypes = {
  id: PropTypes.string,
  icon: PropTypes.any,
  message: PropTypes.any
};
