import React from 'react';

function FooterForm() {
  return (
       <div id="mc_embed_shell">
              <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css" />
                     <div id="mc_embed_signup">
                     <form action="https://veloz.us7.list-manage.com/subscribe/post?u=994a4a1538d32dfd7a36903ab&amp;id=4050eee57b&amp;f_id=00d8c2e1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank">
                     <div className="form-container">
                            <div className="form-left">
                                   <div className="mc-field-group">
                                          <label htmlFor="mce-FNAME" className="hide">First Name </label>
                                          <input type="text" name="FNAME" placeholder="First Name" className="text" id="mce-FNAME" />
                                   </div>
                                   <div className="mc-field-group">
                                          <label htmlFor="mce-LNAME" className="hide">Last Name </label>
                                          <input type="text" name="LNAME" placeholder="Last Name" className="text" id="mce-LNAME" />
                                   </div>
                                   <div className="mc-field-group">
                                          <label htmlFor="mce-EMAIL" className="hide">Email Address <span className="asterisk">*</span></label>
                                          <input type="email" name="EMAIL" placeholder="Your Email Address" className="required email" id="mce-EMAIL" required="" />
                                   </div>
                            </div>
                            <div className="form-right">
                                   <div className="mc-field-group input-group">
                                          <p>Sign me up for:</p>
                                          <ul>
                                          <li>
                                          <input type="checkbox" name="group[4][2]" id="mce-group[4]-4-1" value="" />
                                          <label htmlFor="mce-group[4]-4-1">Consumer</label>
                                          </li>
                                          <li>
                                          <input type="checkbox" name="group[4][4]" id="mce-group[4]-4-2" value="" />
                                          <label htmlFor="mce-group[4]-4-2">Industry</label>
                                          </li>
                                          <li>
                                          <input type="checkbox" name="group[4][1]" id="mce-group[4]-4-0" value="" />
                                          <label htmlFor="mce-group[4]-4-0">Media</label>
                                          </li>
                                          </ul>
                                   </div>
                                   <input type="submit" name="subscribe" id="mc-embedded-subscribe" className="button" value="Subscribe" />
                            </div>
                     </div>
                     <div id="mce-responses" className="clear">
                            <div className="response" id="mce-error-response" style={{display: 'none'}}></div>
                            <div className="response" id="mce-success-response" style={{display: 'none'}}></div>
                     </div>
                     <div aria-hidden="true" style={{position: 'absolute', left: '-5000px'}}>
                            <input type="text" name="b_994a4a1538d32dfd7a36903ab_4050eee57b" tabIndex="-1" value="" />
                     </div>
                     </form>
              </div>
              <script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script>
       </div>
  );
}

export default FooterForm;