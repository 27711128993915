/**
 * Creates table of itemized rows with a total at the bottom. May be used as a more abstracted approach to e.g. 
 * CostToOwnComparison.
 * 
 * Remember: math handling should be handled within functions modules, not here.
 */

import React from "react";
import PropTypes from "prop-types";
import {FormatAsDollars} from "../../../utils/Helpers/Format";
import {GetItemizedTotal} from "../../../functions/misc";

/**
 * 
 * @param {*} param0    props including whether to format as money and a list of items. itemList 
 *                      includes an array of objects, with a name and a numerical value (@TODO - 
 *                      support for multiple numerical amounts). headers is an array of headers if
 *                      applicable (null if not).
 * 
 * @returns DOMElement
 */
const ItemizedTotal = ({moneyFormat, itemList, totalName = 'Total', headers = null}) => {
    // @TODO is that legal to put a default value in a deconstructed argument?

    // @TODO handle headers
    const tableHeader = headers 
        ? (<thead/>)
        : null;
    const totalValue = GetItemizedTotal(itemList);   
    const maybeMoneyFormat = (n) => {
        if(moneyFormat) {
            return FormatAsDollars(n);
        }

        return n;
    }

    return(
        <table 
            className="itemized-cost-table table table-sm table-bordered"
        >
            {tableHeader}
            <tbody>
                {itemList.map((item, i) => {
                    const specialRowClass = item.specialRowClass ? ` ${item.specialRowClass}` : '';    

                    // Don't include 0's in the table.
                    if(item.value === 0) {
                        return null;
                    }

                    return(
                        <tr key={i} className={`itemized-row${specialRowClass}`}>
                            <th 
                                scope="row"
                                className="itemized-name align-middle"
                            >{item.name}</th>
                            <td className="itemized-value align-middle">{maybeMoneyFormat(item.value)}</td>
                        </tr>
                    );
                })}
                <tr className="itemizedRow">
                    <th 
                        scope="row"
                        className="itemized-total-name align-middle"
                    >{totalName}</th>
                    <td className="itemized-total-value align-middle">{maybeMoneyFormat(totalValue)}</td>
                </tr>
            </tbody>
        </table>
    );
}

export default ItemizedTotal;

ItemizedTotal.propTypes = {
    moneyFormat: PropTypes.bool,
    itemList: PropTypes.array,
    headers: PropTypes.array
}