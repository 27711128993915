import React from "react";
import PropTypes from "prop-types";
import HomeChargersIncentiveModifierControls from "../../../../components/HomeChargersFilterControls/HomeChargersIncentiveModifierControls";
import {FormattedMessage, useIntl} from 'react-intl';

const ChargerCatalogModifyIncentives = ({page, zipcode}) => {
    const intl = useIntl();

    const renderIncentiveModifiers = (
        <>
          <HomeChargersIncentiveModifierControls 
            zipcode={zipcode}
          />
        </>
      );

    const renderExpo = (
        <>
            <div className="intro">
                <FormattedMessage 
                    id="chargingadvisor.catalog.selectCharger"
                    defaultMessage="Select a Home Charger"
                    description="Catalog select message"
                />
            </div>
            <div className="sectionBody">
            <FormattedMessage
                id="chargingadvisor.catalog.full_expo"
                defaultMessage="{discover} {website}."
                description="Catalog expo"
                values={
                    {
                        discover: <span>{
                            intl.formatMessage(
                                { 
                                    id: "chargingandiveor.catalog.expo", 
                                    defaultMessage: "Browse home chargers with costs after incentives or learn more with our"
                                }
                            )
                        }</span>, 
                    

                        website: <a 
                            href="https://www.electricforall.org/faqs/"
                            target="_blank"
                            rel="noopener noreferrer"
                            >
                                {intl.formatMessage({ id: "chargingandiveor.catalog.expo.learnmore", defaultMessage: "FAQs"})} 
                            </a>,
                        
                        about: <span>
                            {intl.formatMessage({ id: "chargingandiveor.catalog.expo.about", defaultMessage: " "})} 
                        </span>
                    }
                }            
            />
            </div>
        </>        
    )

    return(
        <div className="row charger-incentive-catalog-top" style={{marginLeft:"0", marginRight:"0",justifyContent: "center"}}>
            <div className="col-xl-3 col-lg-4 col-sm-12">
                {renderExpo}
            </div>
            <div className="col-xl-9 col-lg-8">
                {renderIncentiveModifiers}
            </div>
      </div>
    )
}

export default ChargerCatalogModifyIncentives;

ChargerCatalogModifyIncentives.propTypes = {
    page: PropTypes.string
}