// Added for Veloz Charger Incentive Advisor, where a version of ChargerCard is needed on the point of sale page. 
// First thought was to go for an all CSS approach. While that might be a neat trick I feel it would likely take
// more time than we'd want to spend on it.

import React from "react";
import PropTypes from "prop-types";
import "./LeanChargerCard.scss";

import { FormattedMessage, useIntl } from 'react-intl';
import GaTracker from "../../utils/GaTracker/GaTracker";

const LeanChargerCard = ({
  charger
}) => {
  const intl = useIntl()
  if (!charger) return null;

  const make = charger.make ? charger.make : "";
  const model = charger.model ? charger.model : "";
  const details_link = charger.details_link ? charger.details_link : "";

  const image = charger.img ? charger.img.replace(/ /g, "%20") : "";
  const imgSrc = `${image}`

  let altText = `${make} ${model}`;

  const handleBuyCharger = () => {
    GaTracker.trackEvent({
        category: "Veloz buy charger",
        action: "Clicked buy charger",
        label: `Clicked buy charger ${make}-${model}`,
    });
  };

  const srpBuyButton = (
    <div className="lean-charge-card-buy-button" >
      <a
        href={details_link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <button 
          className="btn btn-ae"
          onClick={handleBuyCharger}
        > {intl.formatMessage({ id: "homeChargersCard.buy-charger", defaultMessage: "Buy Charger" })} </button>
      </a>
    </div>
  );

  const renderTeslaWarning = (make) => {
    if(make !== 'Tesla') {
      return;
    }

    return (
      <div className="charger-compatibility-warning">
        <FormattedMessage
          id="homeChargersCard.teslaWarning"
          description="Warning for Tesla chargers" 
        />
      </div>
    )
  }

  return (
 
         <table className="purchaseChargerTable" >
           <tbody>
            <tr>
              <td align="center">
                <img src={imgSrc} alt={altText} />
              </td>
              <td>
                <div className="lean-charge-card-make">{make}</div>
                <div className="lean-charge-card-model">{model}</div>
                {srpBuyButton}
                {renderTeslaWarning(make)}
              </td>
            </tr>
          </tbody>
        </table>
    
  );
};

export default LeanChargerCard;

LeanChargerCard.propTypes = {
  charger: PropTypes.object
};
