import React, { useContext, useState, useEffect } from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from "prop-types";
// import { NavLink } from "react-router-dom";
import ControlledInputZipcode from "../../client_customizations/components/InputComponents/InputZipcode/ControlledInputZipcode";
import IncentiveCatalog from "../../components/IncentiveCatalog/IncentiveCatalog";
import HomepageIncentiveCarousel from "../../client_customizations/components/HomepageIncentiveCarousel/HomepageIncentiveCarousel"

import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import { ValidateFiveDigitCode } from "../../utils/Helpers/USPostalCodeValidator";
import simpleSampleIncentives from "../../data/samples/simpleSampleIncentives";

import landingSplashImage from "../../client_customizations/assets/images/landingSplashImage.jpg";
import landingDiscoverIncentivesBG from "../../client_customizations/assets/images/landingDiscoverIncentivesBG.jpg";
import discoverIncentives from "../../client_customizations/assets/images/icons/discover-incentives.svg";
import browseChargers from "../../client_customizations/assets/images/icons/browse-chargers.svg";
import applyForIncentives from "../../client_customizations/assets/images/icons/apply-for-incentives.svg";
import GaTracker from "../../utils/GaTracker/GaTracker"

import "./HomeChargerAdvisorLanding.scss";

const HomeChargerAdvisorLanding = ({zipcode}) => {
    const userPrefs = useContext(UserPrefsContext);

    const [workingZipcode, setWorkingZipcode] = useState(zipcode);

    useEffect(() => {
        setWorkingZipcode(zipcode);
      }, [zipcode]);

    const zipcodeValid = ValidateFiveDigitCode(workingZipcode);

    const buttonClickableClass = zipcodeValid ? '' : 'button-click-disabled'

    const handleGetStartedClick = () => {
        GaTracker.trackEvent({
            category: "Veloz change zipcode btn",
            action: "Clicked get started button",
            label: `Clicked get started`,
        });
    };

    const submitButton = (
        <>
      <button
        id="getStartedButton"
        className={`btn btn-ae button-get-started ${buttonClickableClass} `}
        // eslint-disable-next-line 
        aria-url={`${process.env.REACT_APP_PAGES_HOME_CHARGERS_INCENTIVES_CATALOG_NAVLINK}?fgs=true`}
        onClick={handleGetStartedClick}
      >
        <FormattedMessage
          id="getStarted"
          defaultMessage="Get Started"
          description="Get started button on homepage"
        />
      </button>
        </>
    );


    const zipSubmitNav = zipcodeValid ? (
      <>
        {submitButton}
        
      </>
    ) : (
      <>
        {submitButton}
      </>
    );

    const handleChangeZipcode = () => {
        GaTracker.trackEvent({
            category: "Veloz change zipcode",
            action: "Typed veloz zipcode input",
            label: `Changed zipcode`,
        });
    };

    const zappyZIPSearch = () => {
        return (
            <>
                <div className="zappy-zip-input-group row" style={{marginTop: '64px', marginBottom: '64px'}}>
                    <div className="zappy-zip-input col-lg-6 col-xs-12" style={{ width: "100%" }} >
                        <ControlledInputZipcode
                            zipcode={userPrefs.get("zipcode")}
                            workingZipcode={workingZipcode}
                            setZipcode={e => {
                                setWorkingZipcode(e.target.value);
                                if (ValidateFiveDigitCode(e.target.value)) {
                                    userPrefs.set({ zipcode: e.target.value });
                                    // @TODO unhide submit button (idea that the submit button is hidden or dimmed until
                                    // a valid zipcode is entered)
                                }

                                handleChangeZipcode()
                            }
                            }

                            // Since there are two zip code fields on the page we need to make the id's unique.
                            id={`input-zipcode-landing-${Math.random() * 10000000}`}
                            isUpdating={userPrefs.zipcodeIsUpdating}
                            isNotFound={userPrefs.zipcodeIsNotFound}
                            hideValidateRequest
                        />
                        <p className="enter-different-zip">
                            <FormattedMessage
                                id="chargingadvisor.landing.enterDifferentZip"
                                defaultMessage="Enter a different ZIP code to view other areas."
                                description="Instrux to change zip code"
                            />
                        </p>
                    </div>
                    <div className="col-lg-5 col-xs-12">
                        {zipSubmitNav}
                    </div>

                </div>
            </>
        );
    }

    const welcomeSplash = (
      <section className="home-charger-advisor-welcome landing-block container-fluid">
        <div
          className="row"
          style={{
            minWidth: "100%",
            marginLeft: 0,
            marginRight: 0,
          }}
        >
          <div className="col-md-6 welcome-zip-search">
            <h1>
              <FormattedMessage
                id="chargingadvisor.landing.welcome"
                defaultMessage="Welcome to the Home Charging Advisor"
                description="Home Charging Advisor welcome"
              />
            </h1>
            <p style={{ marginBottom: "0px", marginTop: "8px" }}>
              <FormattedMessage
                id="chargingadvisor.landing.findOut"
                defaultMessage="Setting up a home charger is a lot easier and more affordable than you think. Discover the best local incentives and find the charger that's right by entering your ZIP code."
                description="Text under get started CTA"
              />
            </p>
            {zappyZIPSearch()}

            <p style={{ marginTop: "32px" }}>
              <FormattedMessage
                id="chargingadvisor.landing.findOut"
                defaultMessage="Not ready to shop for a home charger just yet? Use the dropdown menus above to explore the full range of helpful tools Electric For All has to offer!"
                description="Text under get started CTA"
              />
            </p>
          </div>
          <div
            className="col-md-6 splash-image"
            style={{
              backgroundImage: "url(" + landingSplashImage + ")",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right center",
              WebkitBackgroundSize: "cover",
              MozBackgroundSize: "cover",
              OBackgroundSize: "cover",
              backgroundSize: "cover",
              padding: 0,
              textAlign: "center",
              height: "762px",
              boxSizing: "border-box",
            }}
            >
            </div>
        </div>
      </section>
    );

    const runes = (
        <section className="home-charger-advisor-icons row component landing-block">
            <div className="col-md-2 d-none d-lg-block d-md-block icon-filler">&nbsp;</div>
            <div className="col-md-8 col-sm-12 advisor-icons-container">
                <div
                    className="row icons-container"
                >
                     <div className="col-xs-4 thick-icon landing-advisor-icon border-left">
                        <img src={browseChargers} alt="Browse Chargers" />
                        <p>
                            <FormattedMessage
                                id="chargingadvisor.landing.browse"
                                defaultMessage="Browse"
                                description="Browse rune"
                            />
                            <br />
                            <FormattedMessage
                                id="chargingadvisor.landing.chargers"
                                defaultMessage="Chargers"
                                description="Chargers rune"
                            />
                        </p>
                    </div>
                    <div className="col-xs-4 first-landing-advisor-icon slim-icon landing-advisor-icon">
                        <img src={discoverIncentives} alt="Discover Incentives" />
                        <p>
                            <FormattedMessage
                                id="chargingadvisor.landing.discover"
                                defaultMessage="Discover"
                                description="Discover rune"
                            />
                            <br />
                            <FormattedMessage
                                id="chargingadvisor.landing.incentives"
                                defaultMessage="Incentives"
                                description="Incentives rune"
                            />
                        </p>
                    </div>
                    <div className="col-xs-4 landing-advisor-icon slim-icon">
                        <img src={applyForIncentives} alt="Apply for Incentivers" />
                        <p>
                            <FormattedMessage
                                id="chargingadvisor.landing.estimate"
                                defaultMessage="Apply for"
                                description="Apply for rune"
                            /><br/><FormattedMessage
                                   id="chargingadvisor.landing.costs"
                                   defaultMessage="Apply for"
                                   description="Apply for rune"
                            />
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-md-2 d-none d-lg-block d-md-block icon-filler">&nbsp;</div>
        </section>
    );

    const sampleIncentives = (
        <section
            className="home-charger-advisor-incentives landing-block row"
            style={{
                backgroundImage: "url(" + landingDiscoverIncentivesBG + ")",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                WebkitBackgroundSize: "cover",
                MozBackgroundSize: "cover",
                OBackgroundSize: "cover",
                backgroundSize: "cover",
                textAlign: "center",
                boxSizing: "border-box"
            }}
        >
            <div className="row component discover-incentives-text-block text-center">
                <div className="col mt-0 hp-incentives-headers">
                    <h2 className="intro">
                        <FormattedMessage
                            id="chargingadvisor.landing.discoverIncentives"
                            defaultMessage="Discover Incentives"
                            description="Discover Incentives header"
                        />
                    </h2>
                    <p className = "Raleway sectionBody col-md-12 ">
                        <FormattedMessage
                            id="chargingadvisor.landing.learnAbout"
                            defaultMessage="Learn about the different types of home charging incentives, including how and when you’ll receive the benefits."
                            description="Text under discover incentives header"
                        />
                    </p>
                </div>
            </div>
            <div className="row component zappy-zip-block">
                <div className="col-md-4">&nbsp;</div>
                <div className="col-md-5 ">
                    {zappyZIPSearch()}
                </div>
                <div className="col-md-4">&nbsp;</div>
            </div>
            <div className="row component hp-incentives-container">
                <div className="col-md-12 d-none d-sm-block d-lg-block d-md-block homepage-incentives">
                    <IncentiveCatalog
                        incentives={simpleSampleIncentives(useIntl())}
                        justify="justify-content-center"
                        linkCards={false}
                    />
                </div>
                <div className="row d-xs-block d-sm-none d-lg-none d-md-none mobile-hp-incentives-container">
                    <HomepageIncentiveCarousel incentives={simpleSampleIncentives(useIntl())} linkCards={false} justify="justify-content-center"/>
                </div>
            </div>
        </section>
    );

    return (
        <div className="home-charger-advisor-landing">
            {welcomeSplash}
            {runes}
            {sampleIncentives}
        </div>
    );
}

export default HomeChargerAdvisorLanding;

HomeChargerAdvisorLanding.propTypes = {
    zipcode: PropTypes.string
  };
