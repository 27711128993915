import React from "react";
import { FormattedMessage } from 'react-intl';
//import { Link } from "react-router-dom";
import "./Footer.scss";
//import SmoothScroll from "../../../../utils/Helpers/SmoothScroll"

import YoutubeIcon from "../../../assets/images/icons/Youtube.svg";
import FacebookIcon from "../../../assets/images/icons/Facebook.svg";
import InstagramIcon from "../../../assets/images/icons/Instagram.svg";
import velozLogoWhite from "../../../assets/images/EFAVeloz-Horizontal-White-1536x326.png";
import FooterForm from "../../FooterForm/FooterForm";
import DisclaimerComponent from "@zappy-ride/disclaimer-footer-component";
import CookieConsent from "react-cookie-consent";

const Footer = ({ language, changeLanguage }) => {
       const socialIcons = (
              <div className="social-icons mb-2">
                     <a
                            href="https://www.facebook.com/ElectricForAll/"
                            target="_blank"
                            rel="noopener noreferrer"
                     >
                            <img className="social-media-icon" alt="Facebook Icon" src={FacebookIcon} />
                     </a>
                     <a
                            href="https://www.youtube.com/@electricforall"
                            target="_blank"
                            rel="noopener noreferrer"
                     >
                            <img className="social-media-icon" alt="Youtube Icon" src={YoutubeIcon} />
                     </a>
                     <a
                            href="https://www.instagram.com/electricforall/"
                            target="_blank"
                            rel="noopener noreferrer"
                     >
                            <img className="social-media-icon" alt="Instagram Icon" src={InstagramIcon} />
                     </a>
              </div>
       );

       return (
              <div className="Footer">
                     <div className="veloz-footer-row">
                            <div className="container">
                                   <div className="veloz-content">
                                          <div className="veloz-logo">
                                                 <img src={velozLogoWhite} alt="Veloz Logo" />
                                          </div>
                                          <p>
                                                 <FormattedMessage
                                                        id="footer.velozMission"
                                                        defaultMessage="Veloz's mission, as a nonprofit organization, is to educate the public regarding the state of electric vehicle technology and its importance to a clean and healthy environment in a non-commercial manner. Veloz does not support or promote any particular car manufacturer and nothing on this website is intended as an endorsement."
                                                        description="Veloz's mission, as a nonprofit organization, is to educate the public regarding the state of electric vehicle technology and its importance to a clean and healthy environment in a non-commercial manner. Veloz does not support or promote any particular car manufacturer and nothing on this website is intended as an endorsement."
                                                 />
                                          </p>
                                   </div>
                                   <div className="veloz-form-container">
                                          <h3>
                                                 <FormattedMessage
                                                        id="footer.formTitle"
                                                        defaultMessage="Join the Electric Vehicle Revolution"
                                                        description="Join the Electric Vehicle Revolution"
                                                 />
                                          </h3>
                                          <p>
                                                 <FormattedMessage
                                                        id="footer.formSubTitle"
                                                        defaultMessage="Get updates about electric vehicles, incentives and rebates."
                                                        description="Get updates about electric vehicles, incentives and rebates."
                                                 />
                                          </p>
                                          <div className="veloz-form">
                                                 <FooterForm />
                                          </div>
                                   </div>
                            </div>
                            </div>

                            <div className="container">
                            <div className="copyright-notice row">
                            <div className="col-md-3">
                            <p style={{ textAlign:"left", marginLeft: -10, color: "#fff", fontSize: "11px" }}>
                                   <span style={{ fontFamily: "Arial, Helvetica" }}>&copy;</span>
                                   {(new Date().getFullYear().toString())} Veloz | <FormattedMessage
                                   id="footer.allRights"
                                   defaultMessage="All Rights Reserved"
                                   description="All Rights Reserved"
                                   />
                            </p>


                            {/* <p style={{ textAlign:"left", marginBottom: 0, color: "#fff", fontSize: "11px" }}>
                                   <span style={{ fontFamily: "Arial, Helvetica" }}> &copy;
                                   {(new Date().getFullYear().toString()) + " | "} J.D. Power ZappyRide (c). {" "}
                                   <a style={{ marginBottom: 0, color: "#fff", fontSize: "11px" }} rel="noopener noreferrer" target="_blank" href="https://zappyride.com/legal-notices">Legal notices.</a>
                                   </span>
                            </p> */}
                            </div>

                            <div className="col-md-6 footer-links">
                            <p style={{ marginBottom: 0, color: "#fff", fontSize: "11px" }}>
                                   <a className="col-3" title="Veloz" href="https://www.electricforall.org/contact-efa/" target="_blank" rel="noopener noreferrer">
                                   <FormattedMessage
                                   id="footer.contact"
                                   defaultMessage="Contact"
                                   description="Contact"
                                   />
                                   </a>
                                   |
                                   <a className="col-3" href="https://www.electricforall.org/privacy-policy/" target="_blank" rel="noopener noreferrer">
                                   <FormattedMessage
                                   id="footer.privacyPolicy"
                                   defaultMessage="Privacy Policy"
                                   description="Privacy Policy"
                                   />
                                   </a>
                                   |
                                   <a className="col-3" href="https://www.electricforall.org/terms-of-service/" target="_blank" rel="noopener noreferrer">
                                   <FormattedMessage
                                   id="footer.termsAndConditions"
                                   defaultMessage="Terms of Service"
                                   description="Terms of Service"
                                   />
                                   </a>
                            </p>
                            </div>
                            <div className="col-md-3 social-links">
                            <div style={{ marginBottom: 0, color: "#fff", fontSize: "11px", textAlign: "right" }}>
                                   {socialIcons}
                            </div>
                            </div>
                            <div className="disclaimer_container">
                                   <DisclaimerComponent clientName="zappynobackground"/>
                            </div>
                            </div>
                     </div>

                     <CookieConsent
                            location="bottom"
                            buttonText="I AGREE"
                            cookieName="velozCookieConsent"
                            style={{ background: "#2B373B" }}
                            expires={150}
                            enableDeclineButton
                            flipButtons
                     >
                            <a href="/privacy-policy">Privacy Policy</a> • <a href="/terms-of-service">Terms of Service</a><br/>Our website - <a href="https://www.electricforall.org">electricforall.org</a> - uses cookies from third party services to improve your browsing experience.
                     </CookieConsent>

              </div>
       );
};

export default Footer;
