import React from "react";
import PropTypes from "prop-types";

const MDLayout = ({args}) => {
    return(
        <>
            {args.renderFilterModal('sm')}
            <div className="row">
                <div className="col-sm-7">
                    {args.renderAllIncentiveCounter(true)}
                </div>
                <div className="col-sm-5">
                    {args.renderSortControl}
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 small-to-mid-modal-button">
                    {args.renderAllIncentivesModal}
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    {args.renderChargerCatalog}
                </div>
            </div>
        </>        
    )
}

export default MDLayout;

MDLayout.propTypes = {
    args: PropTypes.object
};