import PropTypes from "prop-types";
import React from "react";
import {FormattedMessage} from 'react-intl';
import {checkIsMobile} from "../../../functions/misc";
import {FormatAsThousands} from "../../../utils/Helpers/Format"

/**
 * Displays message about the number of products and the top value of incentives. 
 * 
 * @param {*} param0        products: Array of products that are being counted.
 *                          isMobile: Should be true if mobile, false otherwise.
 *                          lease: If true, use amount_in_lease instead of amount_in_purchase          
 */
const ProductIncentiveCounter = ({products, incentives, productName, isMobile = false, leasing = false}) => {
    // Prevents both mobile and non-mobile version fron rendering on page.
    if(checkIsMobile() !== isMobile) {
        return null
    }

    if(products === null) {
        return (null)
    }

    if(products.length === 0) {
        return [];
    }

    const getProductCount = () => {
        return products.length
    }



    const getTotalIncentives = () => {
        const targetIncentiveValue = leasing ? 'amount_in_lease' : 'amount_in_purchase';
        let maxIncentiveValue = 0;

        if(products) {
            products.forEach((product) => {
                let productIncentiveValue = 0;

                if(product.incentives) {
                    product.incentives.forEach((incentive) => {
                        if(incentive.evaluation) {
                            productIncentiveValue += incentive.evaluation[targetIncentiveValue];
                        }
                    });
                }

                maxIncentiveValue = Math.max(maxIncentiveValue, productIncentiveValue);
            });

        }

        return <FormattedMessage
            id="chargingadvisor.totalIncentives"
            defaultMessage="up to {incentiveTotal}"
            description="foundText for mobile"
            values={{
                incentiveTotal: `$${FormatAsThousands(maxIncentiveValue)}`
            }}
    />
    }

    const message = isMobile
        ? (
            <>
                <FormattedMessage
                    id="chargingadvisor.catalog.found.mobile"
                    defaultMessage="{numberFound} results"
                    description="foundText for mobile"
                    values={{
                        numberFound:  <span className="product-counter-count">{ getProductCount() }</span>
                    }}
                />
            </>
        )
        : (
            <>
                <FormattedMessage
                    id="chargingadvisor.catalog.found.desktop"
                    defaultMessage="{numberFound} Home chargers."
                    description="foundText for desktop"
                    values={{
                        numberFound:  <span className="product-counter-count align-top">{ getProductCount() }</span>,
                        incentiveValue: <span className="product-incentive-upto">{getTotalIncentives()}</span>,                    
                    }}
                />
            </>
         )

    return (
        <div className="product-incentive-counter">
            {message}
        </div>
    )
} 

export default ProductIncentiveCounter;

ProductIncentiveCounter.propTypes = {
    products: PropTypes.array,
    incentives: PropTypes.array,
    productName: PropTypes.string,
    leaseOrBuy: PropTypes.bool,
    isMobile: PropTypes.bool
}