import React, { useEffect, useRef, useState } from "react";
import {useIntl} from 'react-intl';
import PropTypes from "prop-types";

import './OverflowDetector.scss';

const OverflowDetector = ({
    text,
    maxHeight,
    classNames = ''
}) => {

    const intl = useIntl();

    const seeLess = intl.formatMessage 
    ? intl.formatMessage({id: 'see-less', defaultMessage:'... see less'})
    : '... see less';

    const seeMore = intl.formatMessage 
    ? intl.formatMessage({id: 'see-more', defaultMessage:'... see more'})
    : '... see more';


    const textEl = useRef(null);
    const seeAllEl = useRef(null);
    const overflow = {
        height: maxHeight,
        overflow: 'hidden'
    };
    const [isExpanded, setExpanded] = useState(false);

    const triggerHTML = isExpanded ?
        (
            <span>... <span className='emph'>{seeLess}</span></span>
        ):    
        (
            <span>... <span className='emph'>{seeMore}</span></span>
        );

    const toggle = () => {
        if(isExpanded) {
            textEl.current.style.overflow="hidden";
            textEl.current.style.height=maxHeight;
        } else {
            textEl.current.style.overflow="visible";
            textEl.current.style.height="auto";
        }
        setExpanded(!isExpanded);
    }

    useEffect(() => {
        const textHeight = textEl.current.scrollHeight;
        const elementHeight = textEl.current.offsetHeight;

        if(textHeight > elementHeight) {
            seeAllEl.current.style = {display:'block'};
        }
    });

    return(
        <>
            <div 
                className={`overflow-detector-box ${classNames}`}
                ref={textEl}
                style={overflow}
            >
                {text}
            </div>
            <div 
                className="see-all"
                ref={seeAllEl}
                style={{
                    display: 'none',
                    cursor: 'pointer'
                }}
                onClick={toggle}
            >{triggerHTML}</div>
        </>
    );
};

OverflowDetector.propTypes = {
    text: PropTypes.string,
    maxHeight: PropTypes.string,
    classNames: PropTypes.string
}

export default OverflowDetector;