import React from "react";
import PropTypes from "prop-types";

const MDLayout = ({args}) => {
    return(
        <>
            <div className="row">
                <div className="col-md-6 ">
                    {args.renderAllIncentiveCounter()}
                </div>
                <div className="col-md-6">
                    {args.renderSortControl}
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 small-to-mid-modal-button">
                    {args.renderAllIncentivesModal}
                </div>
                <div className="col-md-6 small-to-mid-modal-button">
                    {args.renderFilterModal('md')}
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    {args.renderChargerCatalog}
                </div>
            </div>
        </>        
    )
}

export default MDLayout;

MDLayout.propTypes = {
    args: PropTypes.object
};