import React, { useState } from "react";
import PropTypes from "prop-types";
import useWindowSize from '../../../../utils/Hooks/useWindowSize';
import { FormattedMessage } from 'react-intl';

import "./Header.scss";

// CSS from Electric For All
import './thegemcss/gtranslate-style24.css';
import './thegemcss/layerslider.css';
import './thegemcss/style.css';
import './thegemcss/thegem-widgets.css'

// This is fun... our layout on three steps page is broken without this css.
import './thegemcss/thegem-grid.css';
import './thegemcss/thegem-preloader.css';

// Here's hoping we don't need this.. tons of errors in here.
// We do need this for the pipe though.
import './thegemcss/thegem-child-style.css';

// This one gets wornings: Property is ignored due to the display. 
// With 'display: block', vertical-align should not be used.
import './thegemcss/thegem-header.css';
import './thegemcss/thegem-new-css.css';
import './thegemcss/thegem-widgets.css';
import './thegemcss/thegem-perevazka-css.css';
import './thegemcss/custom-n5xN0Kr8.css';
import { DropDownLanguages } from "./DropDownLanguages";


// import './thegemcss/efa.min.css'
// import './thegemcss/efa.css'


const Header = ({ language, changeLanguage }) => {
  const [showNavMenu, setShowNavMenu] = useState(false);
  const [showSubView, setSubView] = useState(false);
  const [parentId, setParentId] = useState('')
 
  const windowSize = useWindowSize();
  const handleClick = (e) => {
    e.preventDefault();
    setShowNavMenu(!showNavMenu);
  }

  const handleSubMenu = (e) => {
    if (windowSize.width < 1000) {
      e.preventDefault();
      setSubView(true)
      setParentId(e.target.dataset.parent)
      if (e.target.dataset.parent === undefined) {
        setSubView(false)
      }
    }
  }

  const onChangeLanguage = (e) => {
    changeLanguage(e.target.value)
  }

  return (
    <>
      <link
        rel="alternate"
        type="application/rss+xml"
        title="Electric For All » Comments Feed"
        href="https://www.electricforall.org/comments/feed/"
      />
      {/* This site uses the Google Analytics by MonsterInsights plugin v7.12.3 - Using Analytics tracking - https://www.monsterinsights.com/ */}
      {/* / Google Analytics by MonsterInsights */}
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html:
            "\nimg.wp-smiley,\nimg.emoji {\n\tdisplay: inline !important;\n\tborder: none !important;\n\tbox-shadow: none !important;\n\theight: 1em !important;\n\twidth: 1em !important;\n\tmargin: 0 .07em !important;\n\tvertical-align: -0.1em !important;\n\tbackground: none !important;\n\tpadding: 0 !important;\n}\n",
        }}
      />
      <link
        href="https://fonts.googleapis.com/css?family=Roboto:400%7CRaleway:800"
        rel="stylesheet"
        property="stylesheet"
        media="all"
        type="text/css"
      />
      <link
        rel="stylesheet"
        id="thegem-google-fonts-css"
        href="//fonts.googleapis.com/css?family=Raleway%3A700%2Cregular%2C100%2C300%7CMontserrat%3A700%2Cregular%7CSource+Sans+Pro%3Aregular%2C300&subset=latin%2Clatin-ext%2Ccyrillic%2Ccyrillic-ext%2Cvietnamese%2Cgreek%2Cgreek-ext&ver=5.5.1"
        type="text/css"
        media="all"
      />

      <div
        id="top-area"
        className="top-area top-area-style-default top-area-alignment-right"
      >
        <div>
          <div className="top-area-items inline-inside">
            <div className="top-area-block top-area-menu">
              <nav id="top-area-menu">
                <ul
                  id="top-area-navigation"
                  className="nav-menu styled inline-inside"
                >
                  <li
                    id="menu-item-24783"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24783"
                  >
                    <a href="https://www.veloz.org/electric-for-all/">Veloz</a>
                  </li>
                  <li
                    id="menu-item-24568"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24568"
                  >
                    <a href="https://www.electricforall.org/media/">
                      <FormattedMessage
                        id="header.media"
                        defaultMessage="Media"
                        description="Media"
                      />
                    </a>
                  </li>
                  <li
                    id="menu-item-24763"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24763"
                  >
                    <a href="https://www.electricforall.org/contact-efa/">
                      <FormattedMessage
                        id="header.contact"
                        defaultMessage="Contact Us"
                        description="Contact Us"
                      />
                    </a>
                  </li>
                  <li className="menu-item menu-item-gtranslate">
                    <DropDownLanguages
                      onChange={onChangeLanguage}
                      value={language}
                    />
                  </li>
                </ul>
              </nav>
            </div>

            <div className="top-area-block top-area-socials socials-colored-hover">
              <div className="socials inline-inside">
                <a
                  className="socials-item"
                  href="https://www.facebook.com/ElectricForAll/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Facebook"
                >
                  <i className="socials-item-icon facebook "></i>
                </a>
                <a
                  className="socials-item"
                  href="https://www.youtube.com/@electricforall"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Youtube"
                >
                  <i className="socials-item-icon youtube "></i>
                </a>
                <a
                  className="socials-item"
                  href="https://www.instagram.com/electricforall/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Instagram"
                >
                  <i className="socials-item-icon instagram "></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="site-header-wrapper"
        className="  sticky-header-on-mobile"
        style={{ height: "115.5px" }}
      >
        <header
          id="site-header"
          className="site-header animated-header mobile-menu-layout-default ios-load"
          role="banner"
        >
          <div className="container">
            <div className="header-main logo-position-left header-layout-default header-style-3">
              <div className="site-title">
                <div className="site-logo" style={{ width: "400px" }}>
                  <a href="https://www.electricforall.org/" rel="home">
                    <span className="logo">
                      <img
                        src="https://www.electricforall.org/wp-content/uploads/thegem-logos/logo_bdfb6420c3367c7ecdcf14a2cc433063_1x.png"
                        srcSet="https://www.electricforall.org/wp-content/uploads/thegem-logos/logo_bdfb6420c3367c7ecdcf14a2cc433063_1x.png 1x,https://www.electricforall.org/wp-content/uploads/thegem-logos/logo_bdfb6420c3367c7ecdcf14a2cc433063_2x.png 2x,https://www.electricforall.org/wp-content/uploads/thegem-logos/logo_bdfb6420c3367c7ecdcf14a2cc433063_3x.png 3x"
                        alt="Electric For All"
                        style={{ width: "400px" }}
                        className="tgp-exclude default"
                      />
                      <img
                        src="https://www.electricforall.org/wp-content/uploads/thegem-logos/logo_89937483090a285911184c6022f1c16d_1x.png"
                        srcSet="https://www.electricforall.org/wp-content/uploads/thegem-logos/logo_89937483090a285911184c6022f1c16d_1x.png 1x,https://www.electricforall.org/wp-content/uploads/thegem-logos/logo_89937483090a285911184c6022f1c16d_2x.png 2x,https://www.electricforall.org/wp-content/uploads/thegem-logos/logo_89937483090a285911184c6022f1c16d_3x.png 3x"
                        alt="Electric For All"
                        style={{ width: "225px", marginLeft: "-400px" }}
                        className="tgp-exclude small"
                      />
                    </span>
                  </a>
                </div>
              </div>
              <nav
                id="primary-navigation"
                className={`site-navigation primary-navigation ${
                  windowSize.width > 1000 ? "" : "responsive"
                } `}
              >
                <button
                  className={`menu-toggle dl-trigger ${
                    showNavMenu ? "dl-active" : ""
                  }`}
                  onClick={(e) => handleClick(e)}
                >
                  Primary Menu
                  <span className="menu-line-1"></span>
                  <span className="menu-line-2"></span>
                  <span className="menu-line-3"></span>
                </button>{" "}
                <ul
                  id="primary-menu"
                  className={`nav-menu styled dl-menu  ${
                    (showNavMenu ? "dl-menuopen" : "") +
                    " " +
                    (windowSize.width > 1000 ? "no-responsive" : "") +
                    " " +
                    (showSubView ? "dl-subview" : "")
                  } `}
                >
                  <li
                    id="menu-item-24753"
                    className={`menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-parent menu-item-24753 megamenu-first-element mobile-clickable ${
                      parentId === "24753" ? `dl-subviewopen` : ``
                    }`}
                  >
                    <a
                      href="/#"
                      data-parent="24753"
                      onClick={(e) => handleSubMenu(e)}
                    >
                      <FormattedMessage
                        id="subheader.exploreEvs"
                        defaultMessage="Explore EVs"
                        description="Explore EVs"
                      />
                    </a>
                    <span className="menu-item-parent-toggle"></span>
                    <ul className="sub-menu styled dl-submenu">
                      <li className="dl-parent">
                        <a href="https://www.electricforall.org/which-car-is-right-for-me/">
                          Show this page
                        </a>
                      </li>
                      <li className="dl-back">
                        <a href="/" onClick={(e) => handleSubMenu(e)}>
                          <FormattedMessage
                            id="subheader.back"
                            defaultMessage="Back"
                            description="Back"
                          />
                        </a>
                      </li>
                      <li
                        id="menu-item-24683"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24683 megamenu-first-element"
                      >
or                        <a href="https://www.electricforall.org/new-evs-and-incentives/">
                          <FormattedMessage
                            id="subheader.newEVs"
                            defaultMessage="New EVs and Incentives"
                            description="New EVs and Incentives"
                          />
                        </a>
                      </li>
                      <li
                        id="menu-item-24683"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24683 megamenu-first-element"
                      >
                        <a href="https://www.electricforall.org/used-evs-and-incentives/">
                          <FormattedMessage
                            id="subheader.usedEVs"
                            defaultMessage="Used EVs and Incentives"
                            description="Used EVs and Incentives"
                          />
                        </a>
                      </li>
                      <li
                        id="menu-item-24899"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24899 megamenu-first-element"
                      >
                        <a href="https://www.electricforall.org/ev-myths/">
                          <FormattedMessage
                            id="subheader.mythbusting"
                            defaultMessage="EV Mythbusting"
                            description="EV Mythbusting"
                          />
                        </a>
                      </li>

                      <li
                        id="menu-item-24899"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24899 megamenu-first-element"
                      >
                        <a href="https://www.electricforall.org/ev-basics/">
                          <FormattedMessage
                            id="subheader.evBasics"
                            defaultMessage="EV Basics"
                            description="EV Basics"
                          />
                        </a>
                      </li>
                      <li
                        id="menu-item-24719"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24719 megamenu-first-element mobile-clickable"
                      >
                        <a href="https://www.electricforall.org/certified-dealers/">
                          <FormattedMessage
                            id="subheader.evDealers"
                            defaultMessage="EV Dealers"
                            description="EV Dealers"
                          />
                        </a>
                      </li>
                      <li
                        id="menu-item-25005"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-25005 megamenu-first-element"
                      >
                        <a href="https://www.electricforall.org/more-ways-to-go-electric/">
                          <FormattedMessage
                            id="subheader.moreWays"
                            defaultMessage="More Ways to Go Electric"
                            description="More Ways to Go Electric"
                          />
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li
                    id="menu-item-25043"
                    className={`menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-parent menu-item-25043 megamenu-first-element ${
                      parentId === "25043" ? `dl-subviewopen` : ``
                    }`}
                  >
                    <a
                      href="/#"
                      data-parent="25043"
                      onClick={(e) => handleSubMenu(e)}
                    >
                      <FormattedMessage
                        id="incentives"
                        defaultMessage="Incentives"
                        description="Incentives"
                      />
                    </a>
                    <span className="menu-item-parent-toggle"></span>
                    <ul className="sub-menu styled dl-submenu">
                      <li className="dl-back">
                        <a href="/" onClick={(e) => handleSubMenu(e)}>
                          <FormattedMessage
                            id="subheader.back"
                            defaultMessage="Back"
                            description="Back"
                          />
                        </a>
                      </li>
                      <li
                        id="menu-item-24547"
                        className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-24545 current_page_item menu-item-24547 megamenu-first-element"
                      >
                        <a href="https://www.electricforall.org/rebates-incentives/">
                          <FormattedMessage
                            id="subheader.evIncentives"
                            defaultMessage="EV Incentives"
                            description="EV Incentives"
                          />
                        </a>
                      </li>
                      <li
                        id="menu-item-24751"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24751 megamenu-first-element"
                      >
                        <a href="https://www.electricforall.org/electric-utility/">
                          <FormattedMessage
                            id="subheader.utilityIncentives"
                            defaultMessage="Utility Incentives"
                            description="Utility Incentives"
                          />
                        </a>
                      </li>
                      <li
                        id="menu-item-25057"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-25057 megamenu-first-element"
                      >
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://homecharging.electricforall.org"
                        >
                          <FormattedMessage
                            id="subheader.homeChargingIncentives"
                            defaultMessage="Home Charging Incentives"
                            description="Home Charging Incentives"
                          />
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li
                    id="menu-item-24551"
                    className={`menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-parent menu-item-24551 megamenu-first-element mobile-clickable menu-item-current ${
                      parentId === "24551" ? `dl-subviewopen` : ``
                    }`}
                  >
                    <a
                      href="https://www.electricforall.org/how-can-i-fill-up/"
                      data-parent="24551"
                      onClick={(e) => handleSubMenu(e)}
                    >
                      <FormattedMessage
                        id="subheader.chargingHydrogen"
                        defaultMessage="Charging"
                        description="Charging"
                      />
                    </a>
                    <span className="menu-item-parent-toggle"></span>
                    <ul className="sub-menu styled dl-submenu">
                      <li className="dl-parent">
                        <a href="https://www.electricforall.org/how-can-i-fill-up/">
                          Show this page
                        </a>
                      </li>
                      <li className="dl-back">
                        <a href="/" onClick={(e) => handleSubMenu(e)}>
                          <FormattedMessage
                            id="subheader.back"
                            defaultMessage="Back"
                            description="Back"
                          />
                        </a>
                      </li>
                      <li
                        id="menu-item-24580"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24580 megamenu-first-element"
                      >
                        <a href="https://www.electricforall.org/charging-with-confidence/">
                          <FormattedMessage
                            id="subheader.chargingConfidence"
                            defaultMessage="Charging with Confidence"
                            description="Charging with Confidence"
                          />
                        </a>
                      </li>
                      <li
                        id="menu-item-25055"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-25055 megamenu-first-element"
                      >
                        <a href="https://www.electricforall.org/ev-charger-locations/">
                          <FormattedMessage
                            id="subheader.findEvChargerLocations"
                            defaultMessage="Find an EV Charger Near You"
                            description="Find an EV Charger Near You"
                          />
                        </a>
                      </li>
                      <li
                        id="menu-item-24818"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-24818 megamenu-first-element menu-item-active"
                      >
                        <a href="https://homecharging.electricforall.org/">
                          <FormattedMessage
                            id="subheader.homeChargingAdvisor"
                            defaultMessage="Home Charging Advisor"
                            description="Home Charging Advisor"
                          />
                        </a>
                      </li>
                      <li
                        id="menu-item-24584"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24584 megamenu-first-element"
                      >
                        <a href="https://www.electricforall.org/how-can-i-fill-up/fuel-cell-electric-vehicles/">
                          <FormattedMessage
                            id="subheader.hydrogenFuelCell"
                            defaultMessage="Hydrogen Fuel Cell EVs"
                            description="Hydrogen Fuel Cell EVs"
                          />
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li
                    id="menu-item-24963"
                    className={`menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-parent menu-item-24963 megamenu-first-element ${
                      parentId === "24963" ? `dl-subviewopen` : ``
                    }`}
                  >
                    <a
                      href="/#"
                      data-parent="24963"
                      onClick={(e) => handleSubMenu(e)}
                    >
                      <FormattedMessage
                        id="subheader.campaign"
                        defaultMessage="Campaigns"
                        description="Campaigns"
                      />
                    </a>
                    <span className="menu-item-parent-toggle"></span>
                    <ul className="sub-menu styled dl-submenu invert">
                      <li className="dl-back">
                        <a href="/" onClick={(e) => handleSubMenu(e)}>
                          <FormattedMessage
                            id="subheader.back"
                            defaultMessage="Back"
                            description="Back"
                          />
                        </a>
                      </li>
                      <li
                        id="menu-item-24962"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24962 megamenu-first-element"
                      >
                        <a href="https://www.electricforall.org/campaign/">
                          <FormattedMessage
                            id="subheader.myths"
                            defaultMessage="Myths Busting Myths"
                            description="Myths Busting Myths"
                          />
                        </a>
                      </li>
                      <li
                        id="menu-item-24960"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24960 megamenu-first-element"
                      >
                        <a href="https://www.electricforall.org/40-million-reasons/">
                          <FormattedMessage
                            id="subheader.reasons"
                            defaultMessage="40 Million Reasons to Go Electric"
                            description="40 Million Reasons to Go Electric"
                          />
                        </a>
                      </li>
                      <li
                        id="menu-item-24962"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24962 megamenu-first-element"
                      >
                        <a href="https://www.electricforall.org/kicking-gas/">
                          <FormattedMessage
                            id="subheader.kickingGas"
                            defaultMessage="Kicking Gas"
                            description="Kicking Gas"
                          />
                        </a>
                      </li>
                      <li
                        id="menu-item-24962"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24962 megamenu-first-element"
                      >
                        <a href="https://www.electricforall.org/opposites-attract/">
                          <FormattedMessage
                            id="subheader.oppositesAttract"
                            defaultMessage="Opposites Attract"
                            description="Opposites Attract"
                          />
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </header>
      </div>
    </>
  );
};

export default Header;

Header.propTypes = {
  page: PropTypes.string
};
