import React, { useContext } from "react";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import { useIntl } from 'react-intl';

const ChargerSortControls = () => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);
  const updateSort = value => {
    let newPrefs = {
      chargerSortType: value.split("-")[0],
      chargerSortDirection: value.split("-")[1]
    };
    userPrefs.set(newPrefs);
  };

  let sortOptionValue = [
    userPrefs.get("chargerSortType"),
    userPrefs.get("chargerSortDirection")
  ].join("-");

  // @TODO need to bold Price and Cord Length
  return (
    <div className="EVsSort">
      <form className="form-inline">
        <label htmlFor="charger-sort-dropdown" className="mr-2">
        </label>
        <select
          id="charger-sort-dropdown"
          className="form-control"
          value={sortOptionValue}
          onChange={e => updateSort(e.target.value)}
        >
          <option value="price-asc">
            {intl.formatMessage ? intl.formatMessage({ 
              id: "homeChargers.sortByPrice", 
              defaultMessage: "Sort by: Price",
              }): "Sort by: Price"
            }
          </option>
          <option value="cord_length-asc">
          {intl.formatMessage ? intl.formatMessage({ 
              id: "homeChargers.sortByCordLength", 
              defaultMessage: "Sort by: Cord Length",
              }): "Sort by: Cord Length"
            }
          </option>
        </select>
      </form>
    </div>
  );
};

export default ChargerSortControls;
