import PropTypes from "prop-types";
import React, { useContext } from "react";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";

import ToolTip from "../shared/ToolTip/ToolTip";

import SlideHomeChargerPrice from "../InputComponents/SlideHomeChargerPrice/SlideHomeChargerPrice"
import SlideHomeChargerCordLength from "../InputComponents/SlideHomeChargerCordLength/SlideHomeChargerCordLength"
import { FormattedMessage, useIntl } from 'react-intl';
import ChargerWifiFilter from "../InputComponents/ChargerWifiFilter/ChargerWifiFilter";
import SelectChargerBrandFilter from "../InputComponents/SelectChargerBrandFilter/SelectChargerBrandFilter";
import sixTwenty from "../../client_customizations/assets/images/icons/6-20.svg"
import sixThirty from "../../client_customizations/assets/images/icons/6-30.svg"
import tenThirty from "../../client_customizations/assets/images/icons/10-30.svg"
import fourteenThirty from "../../client_customizations/assets/images/icons/14-30.svg"
import sixFifty from "../../client_customizations/assets/images/icons/6-50.svg"
import fourteenFifty from "../../client_customizations/assets/images/icons/14-50.svg"
import fourteenSixty from "../../client_customizations/assets/images/icons/14-60.svg"
import dark_i from "../../client_customizations/assets/images/icons/dark_i.svg"

import "./HomeChargersFilterControls.scss";

const HomeChargersFilterControls = ({homeChargers, showWifiFilter = true}) => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);

  const formFactorFilterData = userPrefs.get("chargerFormFactorFilters");
  const chargerTypeFilterData = userPrefs.get("chargerTypeFilters")

  const socketTypeWords = { 
    "6-20" : sixTwenty,
    "6-30" : sixThirty, 
    "10-30" : tenThirty,
    "14-30" : fourteenThirty,
    "6-50" : sixFifty,
    "14-50" : fourteenFifty,
    "14-60" : fourteenSixty,
  }

  const maybeRenderWifiFilterButtons = () => {
    return showWifiFilter ?
      (            
        <ChargerWifiFilter
          label="Wifi Connectivitity"
          id="charger-wifi-filter"
        />
      ) : (<></>)
  }

  const updateFormFactorFilter = key => {
    let newFilters = Object.assign({}, formFactorFilterData);
    newFilters[key] = !newFilters[key];

    userPrefs.set({
        chargerFormFactorFilters: newFilters
    });
  };

  const renderedFormFactorFilters = Object.keys(formFactorFilterData).map(
    (filterKey, i) => {
      const filterName = `${filterKey.charAt(0).toUpperCase()}${filterKey.substr(1)}`;

      const hardWiredClassName = (filterName === 'Hardwired') ?
        ' hardwired-btn' :
        '';
      
      return (
        <button
          className={formFactorFilterData[filterKey] ? `btn active socketType${hardWiredClassName}` : `btn socketType${hardWiredClassName}`}
          key={i}
          type="button"
          aria-pressed={!!formFactorFilterData[filterKey]}
          onClick={() => updateFormFactorFilter(filterKey)}
        >
          <span className="socket-text">
            {filterName === "Hardwired" ? intl.formatMessage({ id: "homeChargers.hardwired", defaultMessage: "Hard Wired"}) :filterName}
          </span>
          {filterName === "Hardwired" ? null : 
            <img className="socket-image" src={socketTypeWords[filterName]} alt={filterName === "Hardwired" ? intl.formatMessage({ id: "homeChargers.hardwired", defaultMessage: "Hardwired"}) :filterName}/>
          }
        </button>
      );
    }
  );

  const updateChargerTypeFilter = selectedKey => {
    const newFilters = Object.keys(chargerTypeFilterData).reduce(function(
      acc,
      key
    ) {
      // Toggle selected key, set all others to false
      acc[key] = key === selectedKey ? !chargerTypeFilterData[key] : false;
      return acc;
    },
    {});

    userPrefs.set({
        chargerTypeFilters: newFilters
    });
  };

  const renderedChargerTypeFilters = Object.keys(chargerTypeFilterData).map(
    (filterKey, i) => {
      let filterName = "";
      switch (filterKey) {
        case "mounted":
          filterName = intl.formatMessage({ id: "homeChargers.wallMounted", defaultMessage: "Wall Mounted"})
          break;
        case "portable":
          filterName = intl.formatMessage({ id: "homeChargers.portable", defaultMessage: "Portable"})
          break;
        default:
      }

      return (
        <button
          className={chargerTypeFilterData[filterKey] ? "btn active text-center" : "btn text-center"}
          key={i}
          type="button"
          onClick={() => updateChargerTypeFilter(filterKey)}
        >
          {filterName}
        </button>
      );
    }
  );

  
  return (
    <div className="input-well ChargerFilterControls">
      <p className="h2">
        <FormattedMessage 
          id="evfilter"
          defaultMessage="Filter"
          description="Filter"
        />
      </p>
      <form>
      <div className="form-group">
          <div className="btn-block-container">
              <SlideHomeChargerPrice 
                label={
                  intl.formatMessage({ 
                    id: "evSort.price", 
                    defaultMessage: "Charger Price" 
                  })
                }
                  
              />
          </div>
        </div>
        <div className="form-group">
          <div className="btn-block-container">
            <SelectChargerBrandFilter chargers={homeChargers} />
          </div>
        </div>
        <div className="form-group">
          <div className="btn-block-container">
              <SlideHomeChargerCordLength
                label={
                  intl.formatMessage({ 
                    id: "homeChargers.cordLength", 
                    defaultMessage: "Cord Length"
                  })
                }
              />
          </div>
        </div>
        <div className="form-group">
          <div className="btn-grid-container">
            {maybeRenderWifiFilterButtons()}
          </div>
        </div>
        <div className="form-group">
          <span className="label-style">
            <FormattedMessage 
              id="vehicle.type"
              defaultMessage="Type"
              description="Type"
            /> 
          </span>
          <ToolTip 
            message={intl.formatMessage ? 
              intl.formatMessage({
                  id:"home-charger-filter.type",
                  defaultMessage: "Some chargers need to be hard wired into your electrical system while others can be plugged in if you have the correct socket available."
              }) : "Some chargers need to be hard wired into your electrical system while others can be plugged in if you have the correct socket available."}    
            icon={dark_i}  
            id="type_tip"
          />
          <div className="btn-grid-container">{renderedChargerTypeFilters}</div>
        </div>
        <div className="form-group">
          <span className="label-style">
            <FormattedMessage 
              id="homeChargers.socket"
              defaultMessage="Socket"
              description="Socket"
            />
            <ToolTip
              message= {intl.formatMessage({ id: "homeChargers.socketTooltip", defaultMessage: "Different model chargers have different plug patterns. These plug patterns correspond to the way prongs fit into your wall outlet. If you're not sure which is best for your home/garage, check with a qualified electrician."})}
              id="socket_tooltip"
              icon={dark_i}
            />
          </span>
          <div className="btn-grid-container socketType-grid-container">{renderedFormFactorFilters}</div>
        </div>
      </form>
    </div>
  );
};

export default HomeChargersFilterControls;

HomeChargersFilterControls.propTypes = {
  showWifiFilter: PropTypes.bool,
  homeChargers: PropTypes.array
}