import React from "react";
import {FormattedMessage, useIntl} from 'react-intl';
import PropTypes from "prop-types";

import OverflowDetector from "../../../components/shared/Textfields/OverflowDetector";
import { FormatAsDollars } from "../../../utils/Helpers/Format";

import "./IncentiveCard.scss";

const IncentiveCard = props => {
  const intl = useIntl();

  let incentive = props && props.incentive ? props.incentive : {};

  let eligibility = incentive.evaluation && incentive.evaluation.eligibility;

  let linkCards = props && (props.linkCards !== undefined) ? props.linkCards : true;

  if(props.buttonText) {
    linkCards = false;
  }

  const renderButton = props.buttonText ?
    ( 
      <div className="buttonLink">
        <a
          className=""
          href={incentive.details_url}
          target="_blank"
          rel="noopener noreferrer"
        >

          <button className="btn btn-ae incentive-card-button">
            <FormattedMessage 
              id="incentive-card.button-text"
              defaultMessage={props.buttonText}
            />
          </button> 
        </a>
      </div>
    ): null;
  
  if (eligibility === "ineligible") {
    return null
  }

  if (props.usedEv === true && props.incentive.applicable_to_used_vehicles === false) {
    return null 
  }
    
  // Because I am getting Rebate from live Incentives but for the sample incentives I am using At Purchase
  if(incentive.type === 'Rebate') {
    incentive.type = 'At Purchase';
  }

  let name = incentive.grantor + " " + incentive.name || "";
  let description = incentive.description || "";

  const findTransID = (incType) => {
    if(!intl.formatMessage) {
      return incType;
    }

    const typeTransIDs = {
      'At Purchase' : intl.formatMessage({ id: "sample-incentives.at-purchase", defaultMessage: "At Purchase" }),
      'At Installation': intl.formatMessage({ id: "sample-incentives.at-install", defaultMessage: "At Installation" }),
      'Tax Credit': intl.formatMessage({ id: "sample-incentives.tax-credit", defaultMessage: "Tax Credit" }),
      'Other Programs': intl.formatMessage({ id: "sample-incentives.other-programs", defaultMessage: "Other Programs"})
    };

    return typeTransIDs[incType] || incType;
  }

  // Keep the English version around for coloring the incentive
  const type_for_color = incentive.type;

  const type = findTransID(incentive.type);

  const secondaryDescription = incentive.description2 || '';
  
  let amountDescription =
    incentive.typical_amount && incentive.typical_amount !== "N/A"
      ? incentive.typical_amount.replace("-$", "-")
      : "";

  let amount =
    incentive.evaluation &&
    incentive.evaluation.amount_in_purchase &&
    parseInt(incentive.evaluation.amount_in_purchase, 10) > 0
      ? parseInt(incentive.evaluation.amount_in_purchase, 10)
      : 0;

  let incentiveClassColor = type => {
    switch (type) {
      case 'Tax credit':
        return 'orange'
      case 'HOV lane':
        return 'orange'
      case 'At Installation':
        return 'orange'  
      case 'Rebate':
        return 'aqua'
      case 'Discounted Electric Rate':
        return 'green'
      case 'At Purchase':
        return 'aqua'
      case 'Other Programs':
        return 'gray'
      default:
        return 'green';
    }
  }



  const renderCardImg = (
    <>
      <div className={`IncentiveCardTop ${incentiveClassColor(type_for_color)}`}>
        <span>{type}</span>
      </div>
      <div className="IncentiveCardTitle">
        {name ? name : ""}         
      </div>
        
        <p className="IncentiveCardIncentiveAmount">{amount ? FormatAsDollars(amount) : amountDescription}</p>
      <div className="pl-5 pr-5">
        <hr />
      </div>
      <div className="IncentiveCardBottom">
        <>
          <div className="card-primary-description">
            <OverflowDetector 
              text={description}
              maxHeight='4.5rem'
            />
          </div>
          <div className="card-secondary-description">
            {secondaryDescription}
          </div>
          {renderButton}
        </>
      </div>
    </>
  );

  let renderCard = incentive ? 
    linkCards ? (
      <a
        className="evc-card IncentiveCard"
        href={incentive.details_url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {renderCardImg}
      </a>
      ) : (
        <div 
          className="evc-card IncentiveCard"
          style={{
            cursor: "default"
          }}
        >
          {renderCardImg}
        </div>
      ) 
   : null;

  return renderCard;
};

export default IncentiveCard;

IncentiveCard.propTypes = {
  incentive: PropTypes.object,
  compact: PropTypes.bool,
  linkCard: PropTypes.bool
};