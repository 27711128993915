
const simpleSampleIncentives = (intl) => {
    intl.formatMessage = intl.formatMessage || function() { return 'string' };

    return([
    {
        "grantor": "",
        "grantor_type": "Country",
        "applicant_type": "Passenger",
        "name": intl.formatMessage({ id: "sample-incentives.home-charging-costs", defaultMessage: "For home charging equipment costs." }),
        "description": intl.formatMessage({ id: "sample-incentives.redeemable-purchase", defaultMessage: "Redeemable after purchase with a receipt." }),
        //"description": "Redeemable after purchase with a receipt.",
        "type": intl.formatMessage({ id: "sample-incentives.at-purchase", defaultMessage: "At Purchase" }),
        "type_for_color": "At Purchase",
        "support_for": "Vehicle purchase",
        "limitations": "",
        "details_url": "https://www.irs.gov/businesses/plug-in-electric-vehicle-credit-irc-30-and-irc-30d",
        "applicable_to_new_vehicles": true,
        "applicable_to_used_vehicles": false,
        "income_dependent": false,
        "applicable_to_fcevs": false,
        "available": true
    },
    {
        "grantor": "",
        "grantor_type": "Country",
        "applicant_type": "Passenger",
        "name": intl.formatMessage({ id: "sample-incentives.install-costs", defaultMessage: "For installation costs, equipment costs, or both." }),
        "description": intl.formatMessage({ id: "sample-incentives.redeem-install", defaultMessage: "Redeemable after installation with a receipt." }),
        "type": intl.formatMessage({ id: "sample-incentives.at-install", defaultMessage: "At Installation" }),
        "type_for_color": "At Installation",
        "support_for": "Vehicle purchase",
        "limitations": "",
        "details_url": "https://www.irs.gov/businesses/plug-in-electric-vehicle-credit-irc-30-and-irc-30d",
        "applicable_to_new_vehicles": true,
        "applicable_to_used_vehicles": false,
        "income_dependent": false,
        "applicable_to_fcevs": false,
        "available": true
    },
    {
        "grantor": "",
        "grantor_type": "Country",
        "applicant_type": "Passenger",
        "name": intl.formatMessage({ id: "sample-incentives.install-costs", defaultMessage: "For installation costs, equipment costs, or both." }),
        "description": intl.formatMessage({ id: "sample-incentives.redeem-filing", defaultMessage: "Redeemable upon filing your state and/or federal income taxes." }),
        "description2": intl.formatMessage({ id: "sample-incentives.income-restrictions", defaultMessage: "Some income restrictions may apply." }),
        "type": intl.formatMessage({ id: "sample-incentives.tax-credit", defaultMessage: "Tax Credit" }),
        "type_for_color": "Tax Credit",
        "support_for": "Vehicle purchase",
        "limitations": "",
        "details_url": "https://www.irs.gov/businesses/plug-in-electric-vehicle-credit-irc-30-and-irc-30d",
        "applicable_to_new_vehicles": true,
        "applicable_to_used_vehicles": false,
        "income_dependent": false,
        "applicable_to_fcevs": false,
        "available": true
    },
    {
        "grantor": "",
        "grantor_type": "Country",
        "applicant_type": "Passenger",
        "name": intl.formatMessage({ id: "sample-incentives.utility-credits", defaultMessage: "Most electric utilities offer special rates, billing credits, and financing options for electric car drivers." }),
        "description": intl.formatMessage({ id: "sample-incentives.offers-vary", defaultMessage: "Offers vary by location."}),
        "type": intl.formatMessage({ id: "sample-incentives.other-programs", defaultMessage: "Other Programs"}),
        "type_for_color": "Other Programs",
        "support_for": "Vehicle purchase",
        "limitations": "",
        "details_url": "https://www.irs.gov/businesses/plug-in-electric-vehicle-credit-irc-30-and-irc-30d",
        "applicable_to_new_vehicles": true,
        "applicable_to_used_vehicles": false,
        "income_dependent": false,
        "applicable_to_fcevs": false,
        "available": true
    }
])
};

export default simpleSampleIncentives;