import React, { useContext, useState } from "react";
import history from "../../../history";
import PropTypes from "prop-types";
import "./ChargerCard.scss";

import typeIcon from "../../assets/images/icons/typeIcon.svg";
import cordLengthIcon from "../../assets/images/icons/cordLengthIcon.svg";
import socketIcon from "../../assets/images/icons/socketIcon.svg";
import timeIcon from "../../assets/images/icons/timeIcon.svg";
import zappyBolt from "../../assets/images/icons/zappyBolt.png";
import wifiIcon from "../../assets/images/icons/icon-wifi.svg";
import ChargerCostBreakdown from "../../../components/CostsCharts/ChargerCostBreakdown/ChargerCostBreakdown";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import { FormatAsTime } from "../../../utils/Helpers/Format";
import { FormattedMessage, useIntl } from "react-intl";
import GaTracker from "../../../utils/GaTracker/GaTracker";

const ChargerCard = ({
  charger,
  selectedVehicle,
  incentivePricing,
  setCostBreakdown = () => {},
  setSelectedCharger = () => {},
}) => {
  const userPrefs = useContext(UserPrefsContext);
  const [chargerCost, setChargerCost] = useState(0);
  const [incentiveValue, setIncentiveValue] = useState(0);
  const [finalPrice, setFinalPrice] = useState(0);

  const intl = useIntl();
  if (!charger) return null;

  const make = charger.make ? charger.make : "";
  const model = charger.model ? charger.model : "";
  const price = charger.price ? charger.price : 0;
  const type = charger.form_factor ? charger.form_factor : "";
  const length = charger.cord_length ? charger.cord_length : 0;
  const socket = charger.home_outlet ? charger.home_outlet : "";
  const details_link = charger.details_link ? charger.details_link : "";
  const enableWifi =
    charger.wifi === "yes" || charger.wifi === true ? true : false;

  const image = charger.img ? charger.img.replace(/ /g, "%20") : "";
  const imgSrc = `${image}`;

  const chargerTypes = {
    Portable: intl.formatMessage({
      id: "homeChargers.portable",
      defaultMessage: "Portable",
    }),
    "Wall mounted": intl.formatMessage({
      id: "homeChargers.wallMounted",
      defaultMessage: "Wall Mounted",
    }),
  };

  const handleChargerSelect = () => {
    GaTracker.trackEvent({
        category: "Veloz select charger",
        action: "Clicked select charger",
        label: `Clicked select charger ${make}-${model}`,
    });
};

  const costBreakdown = {
    costs: {
      val: chargerCost,
      setter: setChargerCost,
    },
    incentives: {
      val: incentiveValue,
      setter: setIncentiveValue,
    },
    finalPrice: {
      val: finalPrice,
      setter: setFinalPrice,
    },
  };

  const updateCostBreakdown = (prop, v) => {
    const oldValue = costBreakdown[prop].val;

    // If we somehow have NaN for both there's something wrong but it at least shouldn't cause
    // a too many renders error
    if (isNaN(v) || isNaN(oldValue)) {
      return;
    }

    if (v !== oldValue) {
      costBreakdown[prop].setter(v);
    }
  };

  let chargerCostBreakdownComponent;
  let altText = `${make} ${model}`;

  const renderChargerDetails = (
    <div className="renderRowOfData">
      <div className="charger-row">
        <img src={typeIcon} alt="wrench" />
        <p>{chargerTypes ? chargerTypes[type] : ""}</p>
      </div>
      <div className="charger-row">
        <img src={cordLengthIcon} alt="double-sided arrow" />
        <p>
          <FormattedMessage
            id="homeChargers.cable"
            defaultMessage="{length} ft. Cable"
            description="cable"
            values={{
              length: length,
            }}
          />
        </p>
      </div>
      <div className="charger-row">
        <img src={socketIcon} alt="plug" />
        <p>
          {socket === "Hardwired" ? (
            intl.formatMessage({
              id: "homeChargers.hardwired",
              defaultMessage: "Hardwired",
            })
          ) : (
            <FormattedMessage
              id="homeChargersCard.socket"
              defaultMessage="{socket} Socket"
              description="Socket"
              values={{
                socket: socket,
              }}
            />
          )}
        </p>
      </div>
      {charger.amps ? (
        <div className="charger-row">
          <img src={zappyBolt} alt="lightning" className="zappy-bolt" />
          <p>{`${(charger.amps * 240) / 1000} kW`}</p>
        </div>
      ) : null}
      {selectedVehicle ? (
        <div className="charger-row">
          <img src={timeIcon} alt="clock" />
          <p>
            {FormatAsTime(
              selectedVehicle.battery_capacity / ((charger.amps * 240) / 1000)
            )}{" "}
            <FormattedMessage
              id="homeChargers.toFullCharge"
              defaultMessage="to full chargeadsf"
              description="to full charge"
            />
          </p>
        </div>
      ) : null}
    </div>
  );

  const renderPrice = () => {
    if (incentivePricing) {
      chargerCostBreakdownComponent = ChargerCostBreakdown({
        installation: userPrefs.get("installCost"),
        charger: charger,
        incentives: charger.incentives,
        updateCostBreakdown: updateCostBreakdown,
      });
      return incentivePrice;
    }
    return srpPrice;
  };

  // Center price on left vertically ... and put line-height of 1 in the scss
  const incentivePrice = (
    <div className="charger-pricing-block">
      <div className="charger-price-and-text row">
        <div className="charger-line-item-cash col-4 text-right">{`$${Math.round(
          charger.price
        )}`}</div>
        <div className="charger-price-exp col-8">
          <FormattedMessage
            id="homeChargersCard.chargerPrice"
            defaultMessage="Charger Price"
            description="Charger Card Price Description"
          />
        </div>
      </div>

      <div className="charger-price-and-text row">
        <div className="charger-line-item-cash col-4 text-right">{`$${Math.round(
          userPrefs.get("installCost")
        )}`}</div>
        <div className="charger-price-exp col-8">
          <FormattedMessage
            id="homeChargersCard.estInstall"
            defaultMessage="Estimated Installation"
            description="Charger Card Price Description"
          />
        </div>
      </div>

      <div className="charger-price-and-text charger-incentive-value row">
        <div className="charger-line-item-cash col-4 text-right">{`-$${Math.round(
          incentiveValue
        )}`}</div>
        <div className="charger-price-exp col-8">
          <FormattedMessage
            id="homeChargersCard.totalIncentives"
            defaultMessage="Total Incentives"
            description="Charger Card Price Description"
          />
        </div>
      </div>

      <div className="charger-price-and-text row">
        <div className="charger-price col-4 text-right">{`$${Math.round(
          finalPrice
        )}`}</div>
        <div className="charger-price-exp charger-price-total col-8">
          <FormattedMessage
            id="homeChargersCard.priceDescription"
            defaultMessage="Total Cost"
            description="Charger Card Price Description"
          />
        </div>
      </div>
    </div>
  );

  const srpPrice = (
    <div>
      <p>
        <span className="charger-price">${price} </span>
        <FormattedMessage
          id="homeChargersCard.beforeIncentives"
          defaultMessage="Before Incentives"
          description="Before Incentives"
        />
      </p>
    </div>
  );

  const incentiveBuyButton = (
    <div
      className="buy-charger"
      onClick={() => {
        userPrefs.set({ selectedChargerId: charger.charger_id });
        setCostBreakdown(chargerCostBreakdownComponent);
        setSelectedCharger(charger);
        handleChargerSelect()
        history.push(
          `${process.env.REACT_APP_PAGES_HOME_CHARGERS_THREE_STEPS_NAVLINK}?fcs=true`
        );
      }}
    >
      <>
        <button className="btn btn-ae selectButton">
          <FormattedMessage
            id="homeChargersCard.selectAndApply"
            defaultMessage="Select &amp; Apply"
            description="Select and Apply"
          />
        </button>
      </>
    </div>
  );

  const srpBuyButton = (
    <div className="buy-charger">
      <a href={details_link} target="_blank" rel="noopener noreferrer">
        <button className="btn btn-ae">
          {" "}
          {intl.formatMessage({
            id: "homeChargersCard.buy",
            defaultMessage: "DETAILS & BUY",
          })}{" "}
        </button>
      </a>
    </div>
  );

  const renderTeslaWarning = (make) => {
    if (make !== "Tesla") {
      return;
    }

    return (
      <div className="charger-compatibility-warning">
        <FormattedMessage
          id="homeChargersCard.teslaWarning"
          description="Warning for Tesla chargers"
        />
      </div>
    );
  };

  const renderCardBottom = (
    <div className="ChargerCardBottom">
      {renderPrice()}
      {incentivePricing ? incentiveBuyButton : srpBuyButton}
    </div>
  );

  return (
    <div className="evc-card ChargerCard">
      <div
        className={`ChargerCardWifiIcon ${
          enableWifi ? "enableIcon" : "disableIcon"
        }`}
      >
        <img src={wifiIcon} alt="Charger is wireless enabled" className="" />
      </div>
      <div className="ChargerCardTop">
        <div
          className="h2 col-12 charger-make"
          style={{ maxWidth: "70%", margin: "0px", padding: "0px" }}
        >
          {make}
        </div>
        <h3
          className="col-12 mt-1 charger-model"
          style={{ maxWidth: "70%", margin: "0px", padding: "0px" }}
        >
          {model}
        </h3>
        <div className="charger-details-and-image">
          <div className="col-xs-6 charger-details">{renderChargerDetails}</div>
          <div className="col-xs-6 charger-image">
            <img src={imgSrc} alt={altText} className="img-fluid" />
            {renderTeslaWarning(make)}
          </div>
        </div>
      </div>
      {renderCardBottom}
    </div>
  );
};

export default ChargerCard;

ChargerCard.propTypes = {
  charger: PropTypes.object,
  selectedVehicle: PropTypes.object,
  incentivePricing: PropTypes.bool,
  setCostBreakdown: PropTypes.func,
  setSelectedCharger: PropTypes.func,
};
