/**
 * Real simple, e.g. for adding up a bill. Takes an array of itemized values and returns a total. 
 * 
 * @param {Array}   arr         Array of itemized names and values
 * @param {String}  valueProp   Property to be totaled 
 */
const GetItemizedTotal = (arr, valueProp = 'value') => {
    let total = 0;

    arr.forEach((item) => {
        total += item[valueProp];
    });

    return total;
}

/**
 * Checks the user agent to determine whether using a mobile device.
 * 
 * @returns: Boolean
 */
const checkIsMobile = () => {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        // true for mobile device
        return true;
    }

    return false;
}

const getViewportWidth = () => {
    return Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0
    )
}

const checkIsBetween = (val, min, max) => {
    if(val < min) {
        return false;
    }
    if(val > max) {
        return false;
    }

    return true;
} 

export {
    GetItemizedTotal,
    checkIsMobile,
    checkIsBetween,
    getViewportWidth
};

