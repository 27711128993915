import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink, Redirect } from "react-router-dom";

import "./ChargerIncentivesThreeSteps.scss";

import LeanChargerCard from "../../components/ChargerCard/LeanChargerCard";

import IncentiveCatalog from "../../client_customizations/components/IncentiveCatalog/IncentiveCatalog";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import PriceDisclaimer from "./PriceDisclaimer";

const ChargerIncentiveThreeSteps = (({
    chargerCostBreakdownComponent,
    charger,
    ...props
}) => {
    useEffect(() => {
        document.title = process.env.REACT_APP_PAGES_HOME_CHARGERS_THREE_STEPS_TITLE;
    });

    const intl = useIntl();

    const params = new URLSearchParams(props.location.search);
    const fcs = params.get("fcs");

    const chargerCatalogNavLink = process.env.REACT_APP_PAGES_HOME_CHARGERS_INCENTIVES_CATALOG_NAVLINK;

    const redirectIfNoCharger = (
        <Redirect
            to={chargerCatalogNavLink}
        />
    );

    if(!charger) {
        return redirectIfNoCharger;
    }

    const backbutton = (
        <div className="three-steps-back-button">
            <NavLink 
                to={chargerCatalogNavLink}
            >
                <button>← {
                    intl.formatMessage 
                    ? intl.formatMessage({
                        "id" : "chargingadvisor.back-to-all-chargers",
                        "defaultMessage" : "Back to All Chuggers"
                    }) 
                    : "Back to All Chiggers"    
                }</button>
            </NavLink>
        </div>
    )

    const costBreakdownVisual = charger
        ? chargerCostBreakdownComponent
        : (<LoadingSpinner />);

    const purchaseChargerVisual = charger
        ? (
            <LeanChargerCard
                charger={charger}
            />
        )
        : (<LoadingSpinner />);
    
    const applyForIncentivesVisual = charger
        ? (
            <IncentiveCatalog
                incentives={charger.incentives}
                excludeZeros={true}
                buttonText={intl.formatMessage({
                    id: "chargingandiveor.catalog.expo.learnmore", 
                    defaultMessage: "Learn More"        
                  })} 
            />
        )
        : (<LoadingSpinner />);    

    return (
      <div className="three-steps">
        {fcs && (
        <>
            <img
            height="1"
            width="1"
            style={{ borderStyle: "none" }}
            alt=""
            src="https://insight.adsrvr.org/track/pxl/?adv=b494u39&ct=0:q3l5omx&fmt=3"
            />
             {/* Start of Floodlight Tag: Please do not remove
  Activity name of this tag: CM | Veloz | Select | Button Click
  URL of the webpage where the tag is expected to be placed: https://homecharging.electricforall.org/home-chargers-incentives-catalog
  This tag must be placed between the <body> and </body> tags, as close as possible to the opening tag.
  Creation Date: 10/18/2022 */}
      <script type="text/javascript">
        var axel = Math.random() + ""; var a = axel * 10000000000000;
        document.write('
        <iframe
          // eslint-disable-next-line no-template-curly-in-string, jsx-a11y/aria-props
          src={"https://11521767.fls.doubleclick.net/activityi;src=11521767;type=mbc;cat=cmvel00;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=' + a + '?"}
          width="1"
          height="1"
          title="chargerPixelA"
          frameBorder="0"
          style={{ display: "none" }}
        ></iframe>
        ');
      </script>
      <noscript>
        <iframe
          // eslint-disable-next-line no-template-curly-in-string, jsx-a11y/aria-props
          src={"https://11521767.fls.doubleclick.net/activityi;src=11521767;type=mbc;cat=cmvel00;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?"}
          width="1"
          height="1"
          title="chargerPixelB"
          frameBorder="0"
          style={{ display: "none" }}
        >

        </iframe>
      </noscript>
      {/* End of Floodlight Tag: Please do not remove */}
      </>)}
        <div className="row mr-0 ml-0">
          <div className="col-xs-12 three-steps-title">
            <FormattedMessage
              id="chargingadvisor.threesteps.header"
              defaultMessage="Three Steps to Get Charging"
              description="Three steps header"
            />
          </div>
        </div>
        <div className="container  col-sm-12 three-steps-steps">
          <div className="col-md-2 col-md-offset-3 col-sm-12 ">
            <FormattedMessage
              id="chargingadvisor.threesteps.reviewCostMessage"
              defaultMessage="{stepNumber} {reviewCost}"
              description="Three steps header reviewCost"
              values={{
                stepNumber: <span className="header-step-number">1.</span>,
                reviewCost: intl.formatMessage({
                  id: "chargingadvisor.threesteps.reviewCost",
                  defaultMessage: "Review Cost",
                }),
              }}
            />
          </div>
          <div className="col-md-2 col-sm-12">
            <FormattedMessage
              id="chargingadvisor.threesteps.purchaseChargerMessage"
              defaultMessage="{stepNumber} {purchaseCharger}"
              description="Three steps header purchaseCharger"
              values={{
                stepNumber: <span className="header-step-number">2.</span>,
                purchaseCharger: intl.formatMessage({
                  id: "chargingadvisor.threesteps.purchaseCharger",
                  defaultMessage: "Purchase Charger",
                }),
              }}
            />
          </div>
          <div className="col-md-2 col-sm-12">
            <FormattedMessage
              id="chargingadvisor.threesteps.purchaseChargerMessage"
              defaultMessage="{stepNumber} {applyIncentives}"
              description="Three steps header purchaseCharger"
              values={{
                stepNumber: <span className="header-step-number">3.</span>,
                applyIncentives: intl.formatMessage({
                  id: "chargingadvisor.threesteps.applyIncentives",
                  defaultMessage: "Apply for Incentives",
                }),
              }}
            />
          </div>
        </div>
        <div
          className="container col-12 full-step-details"
          style={{ paddingTop: "36px" }}
        >
          <div className="col-sm-12 col-md-4">
            <div className="redeem-step-number">1</div>
            <p className="step-header">
              <FormattedMessage
                id="chargingadvisor.threesteps.reviewProjectCost"
                defaultMessage="Review Your Project Cost"
                description="Three steps reviewCost"
              />
            </p>
            <p className="redeem-step-text">
              <FormattedMessage
                id="chargingadvisor.threesteps.otherwiseNeedElectrician"
                defaultMessage="Make sure to review the installation instructions included with the charger you buy.  If you need an electrician, it will typically cost between $500 and $1,200 for the installation, depending on the age of your electrical panel and how far the charging station is from that panel. {learnMore}."
                description="Otherwise you'll need an electrician"
                values={{
                  learnMore: (
                    <a
                      href="https://www.electricforall.org/faqs/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {intl.formatMessage({
                        id: "chargingandiveor.catalog.expo.learnmore",
                        defaultMessage: "Learn more",
                      })}
                    </a>
                  ),
                }}
              />
            </p>
          </div>
          <div className="col-sm-12 col-md-7 col-md-offset-1 redeem-step-visual">
            {costBreakdownVisual}
          </div>
        </div>
        <div className="container col-12 full-step-details">
          <div className="col-sm-12 col-md-4">
            <div className="redeem-step-number">2</div>
            <p className="step-header">
              <FormattedMessage
                id="chargingadvisor.threesteps.purchaseCharger"
                defaultMessage="Purchase Charger"
                description="Purchase charger in page"
              />
            </p>
          </div>
          <div className="col-sm-12 col-md-7 col-md-offset-1 redeem-step-visual">
            {purchaseChargerVisual}
          </div>
        </div>
        <div className="container col-12 full-step-details">
          <div className="col-sm-12 col-md-4">
            <div className="redeem-step-number">3</div>
            <p className="step-header">
              <FormattedMessage
                id="chargingadvisor.threesteps.applyIncentives"
                defaultMessage="Apply for Incentives"
                description="Apply for incentives in page"
              />
            </p>
            <p className="redeem-step-text">
              <FormattedMessage
                id="chargingadvisor.threesteps.saveReceipts"
                defaultMessage="Save your receipts for purchase and installer invoice. You may need a proof of purchase to apply for incentives."
                description="Save your receipts..."
              />
            </p>
          </div>
          <div className="col-sm-12 col-md-7 col-md-offset-1 redeem-step-visual">
            {applyForIncentivesVisual}
          </div>
        </div>
        <div
          style={{ marginBottom: "1rem", marginTop: "1rem" }}
          className="row"
        >
          <div className="col-xs-offset-1 col-xs-10">
            <p className="price-disclaimer">
              <PriceDisclaimer />
            </p>
            <p>{backbutton}</p>
          </div>
        </div>
      </div>
    );
});

export default ChargerIncentiveThreeSteps;

ChargerIncentiveThreeSteps.propTypes = {
    chargerCostBreakdownComponent: PropTypes.element,
    charger: PropTypes.object
}