import PropTypes from "prop-types";
import React, { useContext } from "react";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import { useIntl } from 'react-intl';
import ToolTip from "../../shared/ToolTip/ToolTip";

const ChargerWifiFilter = ({handler, label, tooltip, id}) => {
    handler = handler || function() {};

    const intl = useIntl();
    const userPrefs = useContext(UserPrefsContext);
    const chargerWifi = userPrefs.get("chargerWifiFilter");

    const updateChargerWifiFilter = selectedKey => {
        const newFilters = Object.keys(chargerWifi).reduce(function(
          acc,
          key
        ) {
    
          // Toggle selected key, set all others to false
          acc[key] = key === selectedKey ? !chargerWifi[key] : false;
          return acc;
        },
        {});

        userPrefs.set({
            chargerWifiFilter: newFilters
        });
    };

    const renderChargerWifiFilter = Object.keys(chargerWifi).map(
    (filterKey, i) => {
        let filterName = "";
        switch (filterKey) {
        case "yes":
            filterName = intl.formatMessage({ id: "yes", defaultMessage: "Yes"})
            ;
            break;
        case "no":
            filterName = intl.formatMessage({ id: "no", defaultMessage: "No"});
            break;
        default:
        }

        return (
        <button
            className={  chargerWifi[filterKey] ? "col-md-5 btn active text-center" : "col-md-5 btn text-center"}
            // style={{
            //     width: (95 / 2).toString() + "%",
            //     margin: "1%"
            // }}
            key={i}
            type="button"
            onClick={() => {
                updateChargerWifiFilter(filterKey);
                handler(filterKey);
            }}
        >
            {filterName}
        </button>
        );
    });
    
    return(
        <>
            <label className="modify-charger-incentives-label col-sm-12" htmlFor={id}>
                {label}
                {tooltip && <ToolTip id={id + "_tooltip"} message={tooltip} />}
            </label>
            {renderChargerWifiFilter}
        </>
    );
}

export default ChargerWifiFilter;

// @TODO label should be made FormattedMessage
ChargerWifiFilter.propTypes = {
    handler: PropTypes.func,
    label: PropTypes.string,
    tooltip: PropTypes.string,
    id: PropTypes.string
}