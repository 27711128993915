import * as React from 'react';
import { FormattedMessage } from 'react-intl';

export const DropDownLanguages = ({ onChange, value }) => {

    const options = [
        { label: 'English', value: 'EN' },
        { label: 'Spanish', value: 'ES' },
    ];

    return (
        <select data-testid="DropDownLanguages" className='select-languege-dropdown' value={value} onChange={onChange}>
            {options.map((option) => (
                <FormattedMessage
                    id={'language.options.' + option.value}
                    defaultMessage={option.label}
                    description={option.label}>
                    {(message) => <option key={option.value} value={option.value}>{message}</option>}
                </FormattedMessage>
            ))}
        </select>
    );
}
