const fetchHomeChargers = async params => {  
  // Remove null params
  Object.keys(params).forEach(key => params[key] == null && delete params[key]);

    let url = new URL(
      // @TODO REMOVE!!!
      //'https://api.beta.zappyride.com/chargers'
      //'https://veloz.hca.d.zappyride.com/chargers.json'
      //'http://ev.generic.test/chargers.json'

      // @TODO REINSTATE!!!
      `${process.env.REACT_APP_EV_INFO_API_HOST}/chargers`  
    );
     
    let searchParams = new URLSearchParams(params);  

    url.search = searchParams;

    const response = await window.fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_EV_INFO_TKN}`
      }
    });
  
    const homeChargers = await response.json();

    return homeChargers.chargers;
  };
  
  export default fetchHomeChargers;
  