import React from "react";
import PropTypes from "prop-types";
import IncentiveCard from "./../IncentiveCard/IncentiveCard";
import LoadingSpinner from "./../LoadingSpinner/LoadingSpinner";

const IncentiveCatalog = ({ incentives, titleText, excludeZeros, justify='justify-content-start', linkCards=true, buttonText=null}) => {
  if (!incentives) return <LoadingSpinner />;
  
  const renderCards = incentives
    ? incentives.map((incentive, index) => {
        if(excludeZeros) {
          if(!incentive.evaluation 
            || (incentive.evaluation.amount_in_lease === 0 
              && incentive.evaluation.amount_in_purchase === 0)
          ) {
            return null;
          }
        }

        return (
          <IncentiveCard 
            incentive={incentive} 
            key={index} 
            linkCards={linkCards}
            buttonText={buttonText}
          />);
      })
    : null;

  return (
    <div className="col-md-12">
      {titleText && <p className="h3 text-left">{titleText}</p>}
      <div className={`d-flex ${justify} flex-wrap`}>
        {renderCards}
      </div>
    </div>
  );
};

export default IncentiveCatalog;

IncentiveCatalog.propTypes = {
  incentives: PropTypes.array,
  titleText: PropTypes.element,
  excludeZeros: PropTypes.bool,
  justify: PropTypes.string,
  linkCards: PropTypes.bool,
  buttonText: PropTypes.string,

  // @TODO can we remove this?
  includeTotal: PropTypes.bool
};
